import { useState } from "react";
import logo_aatrac_interbanking from "../img/logo_aatrac_interbanking.png";
import interbanking from "../img/ib_pago.png";
import bna from "../img/bna_pago.png";
import pagomiscuentas from "../img/pagomiscuentas.png";
import linkpagos from "../img/linkpagos.png";
import pagar from "../img/pagar-red_link.png";
import mercadopago from "../img/mp_pago.png";
import pagofacil from "../img/pf_pago.png";
import rapipago from "../img/rapipago.png";
import boleta from "../img/boleta_aatrac.png";
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay-ts';


  export default function BoletaGenerada(props) {

    const [respuesta, setRespuesta] = useState(false);
    const [error, setError] = useState(null);
    const [espera, setEspera]= useState(false);
    

    const GenerarOpPAGAR = () => {
        if(!props.pagaintereses)
            window.id_recargo_empresa = '';
        
        if(props.pagaintereses)
            window.nroPlanilla = '';

        const data = {
            "usuario"               : window.CUIT,
            "token"                 : window.token,
            "nroPlanilla"           : window.nroPlanilla,
            "id_recargo_empresa"    : window.id_recargo_empresa
            
            
        };    
        !respuesta &&
        fetch(`${window.$SERVER_API}generaroperacionPAGAR.php`
        ,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type':'application/json'
                }

            })
            .then((response) => response.json())
            .then((respuesta) => {
                               
                
                if(!respuesta.error)
                {
                                        
                    Swal.fire({
                        width: '45em',
                        
                        imageUrl: pagar,
                        imageHeight: 50,
                        imageAlt: 'AATRAC - Pagar | Red Link',
                        html:
                        '<table>' +
                            '<tr>' +
                                '<td align="left" colspan="3">Usted est&aacute; a punto de generar un alta de deuda en l&iacute;nea de Pagar - Red Link*. Una vez generada, deberá ingresar al Home Banking de su banco para poder realizar el pago, o bien al sitio de Pagar - Red Link.</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" colspan="3"><hr/></td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" colspan="3">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">CUIT</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + window.CUIT +'</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">RAZ&Oacute;N SOCIAL</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + window.desEmpresa + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + (!props.pagaintereses ? 'PLANILLA Nº' : 'BOLETA Nº') + '</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + (!props.pagaintereses ? window.nroPlanilla : window.id_recargo_empresa) + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">CONCEPTO</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + respuesta.CONCEPTO + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">TOTAL A DEPOSITAR</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(window.totalpagarboletagenerada) + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">COMPROBANTE</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + respuesta.COMPROBANTE + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                            '<td align="left" colspan="3" style="margin-bottom:4px;font-size: 0.75em;color:#cacaca;">*Debe ser cliente de un banco de la red Link o de Pagar. Si va a pagar por primera vez, deberá buscar su pago en la Categoría: Cobranzas para Empresas y Comercios, ingresando su código de pago electrónico e identificando el número de cuota y el importe.<br/>&nbsp;<a style="font-size:0.6em" href="https://pagar.redlink.com.ar/" target="_blank" title="Pagar - Red Link" rel="noreferrer">Más información</a></td>' +
                            '</tr>' +
                        '</table>'
                          
                          ,
                          
                        showCancelButton: true,
                        confirmButtonText: 'Generar pago Red Link',
                        cancelButtonText: 'Cancelar'
                        }).then((result) => {
            
                            if (result.isConfirmed) {
                                                        
                                const data = {
                                    "usuario"               : window.CUIT,
                                    "token"                 : window.token,
                                    "nroPlanilla"           : window.nroPlanilla,
                                    "id_recargo_empresa"    : window.id_recargo_empresa,
                                    "COMPROBANTE"           : respuesta.COMPROBANTE,
                                    "codConcepto"           : respuesta.codConcepto,
                                    "impDepositado"         : window.totalpagarboletagenerada

                                    
                                };
                        
                                setEspera(true);                        
                                fetch(`${window.$SERVER_API}generaraltadedeuda.php`
                                ,
                                    {
                                        method: 'POST',
                                        body: JSON.stringify(data),
                                        headers: {
                                            'Content-Type':'application/json'
                                        }
                        
                                    })
                                    .then((response) => response.json())
                                    .then((respuesta) => {
                                        setEspera(false);
                                        if(!respuesta.error){
                                            
                                            Swal.fire({
                                                icon: 'success',
                                                html:
                                                 '<p>COMPROBANTE/CUOTA Nº ' + respuesta.COMPROBANTE  + '</p>' +
                                                                                                  
                                                 '<p>' + '<a href="' + (respuesta.id_recargo_empresa ==='' ? `${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${respuesta.nroPlanilla}` : `${window.$SERVER_API}fpdf185/planilla.php?id_recargo_empresa=${respuesta.id_recargo_empresa}`) + '" target="_blank" rel="noreferrer">Descargar boleta</a> ' + '</p>' +
                                                 
                                                 '<p align="left" style="margin-bottom:9px;font-size: 0.96em;color:#333;">C&oacute;digo de pago electr&oacute;nico: ' + respuesta.cpe + '</p>' +
                                                 '<p align="left" style="margin-bottom:4px;font-size: 0.96em;color:#999;">Deberá acceder a su Home Banking para poder efectuar el pago, o bien desde el sitio de Pagar - Red Link.</p>' +
                                                 '<p><a style="font-size:0.9em" href="https://pagar.redlink.com.ar/" target="_blank" title="Pagar - Red Link" rel="noreferrer">Ir al sitio de Pagar - Red Link</a></p>',

                                                confirmButtonText:
                                                  '<i class="fa fa-thumbs-up"></i> Cerrar',
                                                confirmButtonAriaLabel: 'Thumbs up, great!'
                                                
                                              })
                                            //Swal.fire(respuesta.codebar, '', 'success');
                                            props.operar();
                                            window.scroll({
                                                top: 0,
                                                left: 0,
                                                behavior: 'smooth'
                                            });
                                        
                                        }
                                        else {
                                            setError(respuesta.error);
                                            Swal.fire(respuesta.error, '', 'error');
                                            
                                        }
                                        
                                    
                                    });
                            
                            
                            }
            
            
                      })
                
                    
                }
                else {
                    setError(respuesta.error);
                    //console.log(respuesta.error)
                    Swal.fire(respuesta.error, '', 'error')
                }
                
            
            });
    }
    
    
    const GenerarOpVEP = () => {
        if(!props.pagaintereses)
            window.id_recargo_empresa = '';
        
        if(props.pagaintereses)
            window.nroPlanilla = '';

        const data = {
            "usuario"               : window.CUIT,
            "token"                 : window.token,
            "nroPlanilla"           : window.nroPlanilla,
            "id_recargo_empresa"    : window.id_recargo_empresa
            
        };
setEspera(true);
        !respuesta &&
        fetch(`${window.$SERVER_API}generaroperacionib.php`
        ,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type':'application/json'
                }

            })
            .then((response) => response.json())
            .then((respuesta) => {
                               
    setEspera(false);
                if(!respuesta.error)
                {
                    Swal.fire({
                        width: '45em',
                        
                        imageUrl: logo_aatrac_interbanking,
                        imageHeight: 50,
                        imageAlt: 'AATRAC - Interbanking',
                        html:
                        '<table>' +
                            '<tr>' +
                                '<td align="left" colspan="3">Usted est&aacute; a punto de generar una preconfección BtoB*. Una vez generada, deberá ingresar al portal de Interbanking para poder efectuar la transferencia.</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" colspan="3"><hr/></td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" colspan="3">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">CUIT</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + window.CUIT +'</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">RAZ&Oacute;N SOCIAL</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + window.desEmpresa + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + (!props.pagaintereses ? 'PLANILLA Nº' : 'BOLETA Nº') + '</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + (!props.pagaintereses ? window.nroPlanilla : window.id_recargo_empresa) + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">CONCEPTO</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + respuesta.CONCEPTO + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">TOTAL A DEPOSITAR</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(window.totalpagarboletagenerada) + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">COMPROBANTE</td>' +
                                '<td align="left" style="margin-bottom:4px;border-bottom: 1px solid #dadada;font-size: 0.875em;">' + respuesta.COMPROBANTE + '</td>' +
                                '<td align="left" width="20%" style="padding-bottom:9px;">&nbsp;</td>' +
                            '</tr>' +
                            '<tr>' +
                                '<td align="left" colspan="3" style="margin-bottom:4px;font-size: 0.75em;color:#cacaca;">*Debe estar adherido al servicio BtoB de interbanking<br/>&nbsp;<a style="font-size:0.6em" href="https://www.interbanking.com.ar" target="_blank" title="Interbanking" rel="noreferrer">Más información</a></td>' +
                            '</tr>' +
                        '</table>'
                          
                          ,
                          
                        showCancelButton: true,
                        confirmButtonText: 'Generar pago BtoB',
                        cancelButtonText: 'Cancelar'
                        }).then((result) => {
            
                            if (result.isConfirmed) {
    setEspera(true);
                            
                                const data = {
                                    "usuario"               : window.CUIT,
                                    "token"                 : window.token,
                                    "nroPlanilla"           : window.nroPlanilla,
                                    "id_recargo_empresa"    : window.id_recargo_empresa,
                                    "COMPROBANTE"           : respuesta.COMPROBANTE,
                                    "codConcepto"           : respuesta.codConcepto,
                                    "impDepositado"         : window.totalpagarboletagenerada

                                    
                                };
                        
                        
                                fetch(`${window.$SERVER_API}generarpreconfeccion.php`
                                ,
                                    {
                                        method: 'POST',
                                        body: JSON.stringify(data),
                                        headers: {
                                            'Content-Type':'application/json'
                                        }
                        
                                    })
                                    .then((response) => response.json())
                                    .then((respuesta) => {
    setEspera(false);
                                        if(!respuesta.error){
                                            
                                            Swal.fire({
                                                icon: 'success',
                                                html:
                                                 '<p>COMPROBANTE Nº ' + respuesta.COMPROBANTE  + '</p>' +
                                                                                                  
                                                 '<p>' + '<a href="' + (respuesta.id_recargo_empresa ==='' ? `${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${respuesta.nroPlanilla}` : `${window.$SERVER_API}fpdf185/planilla.php?id_recargo_empresa=${respuesta.id_recargo_empresa}`) + '" target="_blank" rel="noreferrer">Descargar boleta</a> ' + '</p>' +
                                                 
                                                 '<p align="left" style="margin-bottom:9px;font-size: 0.96em;color:#333;">' + respuesta.preconfeccion + '</p>' +
                                                 '<p align="left" style="margin-bottom:4px;font-size: 0.96em;color:#999;">Deberá acceder a interbanking para efectuar el pago BtoB</p>' +
                                                 '<p><a style="font-size:0.9em" href="https://www.interbanking.com.ar" target="_blank" title="Interbanking" rel="noreferrer">Ir a interbanking</a></p>',

                                                confirmButtonText:
                                                  '<i class="fa fa-thumbs-up"></i> Cerrar',
                                                confirmButtonAriaLabel: 'Thumbs up, great!'
                                                
                                              })
                                            
                                            props.operar();
                                            window.scroll({
                                                top: 0,
                                                left: 0,
                                                behavior: 'smooth'
                                            });
                                        
                                        }
                                        else {
                                            setError(respuesta.error);
                                            Swal.fire(respuesta.error, '', 'error');
                                            
                                        }
                                        
                                    
                                    });
                            
                            
                            }
            
            
                      })
                
                    
                }
                else {
                    setError(respuesta.error);
                    
                    Swal.fire(respuesta.error, '', 'error')
                }
                
            
            });
    
    
    

    }
    
    return(
    <div><h5>Boleta Generada</h5>
    
        <br/>
        {!props.pagaintereses &&<>
            <iframe src={`${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${window.nroPlanilla}`} height="600" width="800" title="Iframe Boleta"></iframe>
            <br/>
            Planilla Nº&nbsp;
            <a href={`${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${window.nroPlanilla}`} target="_blank" title="Ver" rel="noreferrer">{window.nroPlanilla}</a>
            <br/>
            <a href={`${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${window.nroPlanilla}`} target="_blank" title="Descargar" rel="noreferrer">Descargar</a>
        </>
        }
        {props.pagaintereses &&
        <>
            <iframe src={`${window.$SERVER_API}fpdf185/planilla.php?id_recargo_empresa=${window.id_recargo_empresa}`} height="600" width="800" title="Iframe Example"></iframe>
            <br/>
            Boleta Nº&nbsp;
            <a href={`${window.$SERVER_API}fpdf185/planilla.php?id_recargo_empresa=${window.id_recargo_empresa}`} target="_blank" title="Ver" rel="noreferrer">{window.id_recargo_empresa}</a>
            <br/>
            <a href={`${window.$SERVER_API}fpdf185/planilla.php?id_recargo_empresa=${window.id_recargo_empresa}`} target="_blank" title="Descargar" rel="noreferrer">Descargar</a>
        </>}
        <br/>Total ARS: {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(window.totalpagarboletagenerada)}
        <br/>
        <br/><h4>Pagar boleta</h4>
        <br/><h4>Medios de pago disponibles</h4>
        {espera &&
            <LoadingOverlay
            active={true}
            spinner
            text='Cargando respuesta del servidor...'            >
            <p><br/><br/></p><br/><br/><br/>
            </LoadingOverlay>}
        <br/>
        <div className="row">
                <div className="col-md-2 text-center">
                
                <img src={pagar} alt="Pagar - Red Link" width={144}/>
                <>
                <br/>
                {!props.pagaintereses && 
                <button
                onClick={GenerarOpPAGAR} 
                type="button" 
                className="btn btn-light btn-sm">
                    Generar VEP
                </button> 
                }
                {props.pagaintereses &&
                <button
                onClick={GenerarOpPAGAR} 
                type="button" 
                className="btn btn-light btn-sm">
                    Generar VEP
                </button>  
                }
                </>
            </div>
            <div className="col-md-2 text-center">
                <img src={bna} alt="BNA" width={200}/>
                <p>
                {!props.pagaintereses &&<a href={`${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${window.nroPlanilla}`} target="_blank" title="Descargar" rel="noreferrer">Descargar</a>}
                {props.pagaintereses &&<a href={`${window.$SERVER_API}fpdf185/planilla.php?id_recargo_empresa=${window.id_recargo_empresa}`} target="_blank" title="Descargar" rel="noreferrer">Descargar</a>}
                </p>
            </div>
            <div className="col-md-2 text-center">
                <img src={interbanking} alt="Interbanking" width={200}/>
                <>
                <br/>
                {!props.pagaintereses &&
                <button
                onClick={GenerarOpVEP} 
                type="button" 
                className="btn btn-light btn-sm">
                    Generar VEP
                </button> 
                }
                {props.pagaintereses &&
                <button
                onClick={GenerarOpVEP} 
                type="button" 
                className="btn btn-light btn-sm">
                    Generar VEP
                </button> 
                }
                </>
            </div>
            <div className="col-md-2 text-center">
                <img src={mercadopago} alt="Mercado Pago" width={200}/>
                <p>
                {!props.pagaintereses &&<a href={`${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${window.nroPlanilla}`} target="_blank" title="Escanear desde la App con su celular" rel="noreferrer">Pago en línea</a>}
                {props.pagaintereses &&<a href={`${window.$SERVER_API}fpdf185/planilla.php?id_recargo_empresa=${window.id_recargo_empresa}`} target="_blank" title="Escanear desde la App con su celular" rel="noreferrer">Pago en línea</a>}
                </p>
            </div>
            <div className="col-md-2 text-center">
                <img src={pagofacil} alt="Pago Facil" width={200}/>
                <p>
                {!props.pagaintereses &&<><a href={`${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${window.nroPlanilla}`} target="_blank" title="Descargar" rel="noreferrer">Descargar</a> | <a href="https://pagosenlinea.pagofacil.com.ar/" target="_blank" title="Pagar online" rel="noreferrer">Pagar online</a></>}
                {props.pagaintereses &&<><a href={`${window.$SERVER_API}fpdf185/planilla.php?id_recargo_empresa=${window.id_recargo_empresa}`} target="_blank" title="Descargar" rel="noreferrer">Descargar</a> | <a href="https://pagosenlinea.pagofacil.com.ar/" target="_blank" title="Pagar online" rel="noreferrer">Pagar online</a></>}
                </p>
            </div>    
        </div>
        
        <br/><br/>
        <h6><strong>IMPORTANTE: NO SE ACEPTAN TRANSFERENCIAS BANCARIAS</strong></h6>
        <h6>Cód.LINK: {0+window.CUIT.replace(/-/g, '')}</h6>
        
    </div>
    );
}