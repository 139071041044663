
export default function Contacto(props) {
    return(
        <div>
            <br/><br/><br/><br/><br/><br/>
            <h3><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
</svg>&nbsp;Contacto&nbsp;</h3>
            
                <div className="row">
                    <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row bg-light">
                    <div className="col-md-12">&nbsp;</div>
                </div>
                <div className="row bg-light">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12"><h5>AATRAC - Asociación Argentina de Trabajadores de las Comunicaciones</h5></div>
                        </div>
                        <div className="row bg-light">
                            <div className="col-md-12"><h5>&nbsp;</h5></div>
                        </div>
                        <div className="row bg-light">
                            <div className="col-md-6"><h6><strong>Domicilio</strong></h6></div>
                            <div className="col-md-6">&nbsp;</div>
                        </div>
                        <div className="row bg-light">
                            <div className="col-md-6">Chacabuco 140 - (C1069AAD) </div>
                            <div className="col-md-6">Ciudad Autónoma de Buenos Aires</div>
                        </div>
                        <br/>
                        <div className="row bg-light">
                            <div className="col-md-6"><h6><strong>Teléfono</strong></h6></div>
                            <div className="col-md-6">&nbsp;</div>
                        </div>
                        <div className="row bg-light">
                            <div className="col-md-6">(+5411) 4335-4300</div>
                            <div className="col-md-6">&nbsp;</div>
                        </div>
                        <br/>
                        <div className="row bg-light">
                            <div className="col-md-6"><h6><strong>Sitio institucional</strong></h6></div>
                            <div className="col-md-6">&nbsp;</div>
                        </div>
                        <div className="row bg-light">
                            <div className="col-md-6"><a href="https://www.aatrac.org.ar" target="_blank" rel="noreferrer">https://www.aatrac.org.ar</a></div>
                            <div className="col-md-6">institucional@aatrac.org.ar</div>
                        </div>
                    </div>
                    <div className="col-md-6"><iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.7802400627997!2d-58.37879982519582!3d-34.60971815781345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccad3ebce8341%3A0x36dfaddc36da066!2sChacabuco%20140%2C%20C1069AAD%20CABA!5e0!3m2!1ses!2sar!4v1684689357089!5m2!1ses!2sar" width="400" height="350" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe></div>
                </div>
                <div className="row bg-light">
                    <div className="col-md-12">&nbsp;</div>
                </div>
            <br/>
            
            <br/>
            <br/><br/><br/>
            <br/>
        </div>
    
    )
}