import {useState} from "react";

export default function Consulta(props){

    const[espera, setEspera]= useState(false);
    const [respuesta, setRespuesta] = useState([]);
    const [error, setError] = useState(null);



    return (
        <div>
            <h3><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16"><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"></path></svg>&nbsp;Consultas</h3>
            
            <div className="row">
                <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <br/>
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6"><h5>Consultas generales</h5></div>
                <div className="col-md-6">&nbsp;</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-12">&nbsp;</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-3"><h6><strong>Asunto</strong></h6>Alta de emisora - Nómina de empleados - Fechas de vencimiento</div>
                <div className="col-md-3"><h6><strong>Contacto</strong></h6></div>
                <div className="col-md-3"><h6><strong>email</strong></h6>institucional@aatrac.org.ar</div>
                <div className="col-md-3"><h6><strong>Teléfono</strong></h6>(+5411) 4335-4300</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-12">&nbsp;</div>
            </div>
            <hr/>
            <br/>
            <div className="row">
                <div className="col-md-6"><h5>Consultas administrativas</h5></div>
                <div className="col-md-6">&nbsp;</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-12">&nbsp;</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-3"><h6><strong>Asunto</strong></h6>Conceptos - Pagos - DDJJ - Deudas</div>
                <div className="col-md-3"><h6><strong>Contacto</strong></h6></div>
                <div className="col-md-3"><h6><strong>email</strong></h6>dptofiscalizacion@aatrac.org.ar</div>
                <div className="col-md-3"><h6><strong>Teléfono</strong></h6>(+5411) 4335-4300 interno 109</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-12">&nbsp;</div>
            </div>
            <hr/>
            <br/>
            <div className="row">
                <div className="col-md-6"><h5>Consultas técnicas</h5></div>
                <div className="col-md-6">&nbsp;</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-12">&nbsp;</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-3"><h6><strong>Asunto</strong></h6>Blanqueo de clave - Impresión de boletas</div>
                <div className="col-md-3"><h6><strong>Contacto</strong></h6></div>
                <div className="col-md-3"><h6><strong>email</strong></h6>soporte.aatrac@gmail.com</div>
                <div className="col-md-3"><h6><strong>Teléfono</strong></h6>(+5411) 4335-4300</div>
            </div>
            <div className="row bg-light">
                <div className="col-md-12">&nbsp;</div>
            </div>
            
          
            <br/>
            <br/>
            <br/>
            <br/>
            <br/><br/><br/><br/>
        </div>
    );
}