import React, {useRef, useState, useEffect} from "react";
import usuario from "../img/usuario.png";

export default function ContainerNoLogged(props) {

const urlLOGIN = `${window.$SERVER_API}login.php`;

const enviarData = async(url, data)=>{

    const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type':'application/json'
        }

    });

    const json = await resp.json();

    return json;

}

    const handleRegistration = () => {

        props.registrar(true);
        };
    
    const handleContacto = () => {

        props.contactar(true);
        };
    
    const handleClave = () => {

        props.ingresar(true);
    };

    const[error, setError]= useState(null);
    const[espera, setEspera]= useState(false);

    const refCUIT = useRef(null);
    const refClave = useRef(null);

    const handleLogin = async()=>{
        setEspera(true);

        const data = {
            "usuario" : refCUIT.current.value,
            "clave"   : refClave.current.value
        };


        const respuestaJson = await enviarData(urlLOGIN, data);
        props.acceder(respuestaJson.conectado);
        
        respuestaJson.conectado ? window.token = respuestaJson.token : props.acceder(false);
        respuestaJson.conectado ? window.CUIT = respuestaJson.usuario : props.acceder(false);
        respuestaJson.conectado ? window.desEmpresa = respuestaJson.desEmpresa : props.acceder(false);
        setError(respuestaJson.error);
        setEspera(false);

    }

    return(
        <div className="container mt-5">
            <br/><br/><br/>
            <div className="row">
                <div className="col-lg-8">

                    <article>
                        <div id="login"></div>

                        <header className="mb-4">

                            <h1 className="fw-bolder mb-1">👋¡Hola!</h1>
                            <div>Boletas AATRAC le da la bienvenida</div>


                            <a className="badge bg-secondary text-decoration-none link-light" style={{'margin':'2px'}} href="https://www.aatrac.org.ar" target="_blank" rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door-fill" viewBox="0 0 16 16">
  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
</svg>&nbsp;aatrac.org.ar</a>
                            <li
                            onClick={handleRegistration}
                            className="badge bg-secondary text-decoration-none link-light"
                            style={{'margin':'2px','cursor':'pointer'}} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen-fill" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z"/>
</svg>&nbsp;
                                Registrarse</li>
                            <li
                            className="badge bg-secondary text-decoration-none link-light"
                            style={{'margin':'2px','cursor':'pointer'}}
                            onClick={handleContacto}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
</svg>&nbsp;
                                Contacto</li>
                            <br/><br/><br/>
                        </header>

                        <figure className="mb-4"><img className="img-fluid rounded" src={usuario} width="560" alt="" /></figure>

                    </article>

                </div>

                <div className="col-lg-4">

                    <div className="card mb-4">
                        <div className="card-header"><strong><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-lock2" viewBox="0 0 16 16">
  <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z"/>
  <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
</svg>&nbsp;Ingresar</strong></div>

                        <div className="card-body">
                        <form onSubmit={handleLogin}>
                            <div className="input-group">
                            <input
                                    id="inputmask"
                                    className="form-control"
                                    type="text"
                                    placeholder="CUIT/CUIL"
                                    aria-label="CUIT/CUIL"
                                    aria-describedby="button-search"
                                    data-mask="99-99999999-9"
                                    ref={refCUIT}
                                    autoComplete={"off"} />
                            </div>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    type="password"
                                    placeholder="Clave de 8 caracteres"
                                    aria-label="Clave de 8 caracteres"
                                    aria-describedby="button-search"
                                    maxLength={8}
                                    ref={refClave} />
                            </div><br />

                            {
                                error &&
                               <div className="alert alert-danger"> {error}</div>
                            }


                            <div className="input-group" style={{ 'paddingLeft': '110px' }}>



                                <input type="submit"

                                    onClick={handleLogin}
                                    disabled={espera}
                                    className="btn btn-danger"
                                    id="button-search"
                                    value="Ingresar"
                                />

                            </div>
                        </form>
                            <div><br />
                            <div 
                            className="nav-link" 
                            style={{'fontSize':'12px','cursor':'pointer'}}
                            onClick={handleClave}>Olvidé mi clave
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header"><strong>Importante</strong></div>
                        <div className="card-body" style={{'textAlign':'justify'}}>Con relación a la generación de las boletas de pago y a los efectos de evitar sanciones previstas en la Ley Nº 25.326 de Protección de Datos Personales, a partir del 20/08/2013 deberá ingresar al sistema con su CUIT/CUIL y una clave de 8 caracteres.
                            <br/>Si usted ingresa por primera vez, debe registrarse.
                            </div>
                    </div>
                </div>
            </div>
        </div>

   )
}