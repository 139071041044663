import {useState, useEffect} from "react";
import arrowimg from "../img/updownarrow.svg";
import LoadingOverlay from 'react-loading-overlay-ts';

export default function MisAportes(props) {

    const urlMisAportes = `${window.$SERVER_API}misaportes.php`;


    const enviarData = async(url, data)=>{

        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        });
        const json = await resp.json();

        return json;

    }

        const [respuesta, setRespuesta] = useState();
        const [error, setError]         = useState(null);
        const [espera, setEspera]       = useState(false);
        
        const [filtro, setFiltro]       = useState(null);
        let nuevo = null;
        
        const handleMisAportes = async()=>{
            
            setRespuesta([]);
            setEspera(true);
            const data = {
                "usuario" : window.CUIT,
                "token"   : window.token


            };

        const respuestaJson =  await enviarData(urlMisAportes, data);

        setRespuesta(respuestaJson);
        setError(respuestaJson.error);
        setEspera(false);
    }
    const FilterTotal = () => {
        filtro === 'total+' ? setFiltro('total-') : setFiltro('total+')
    }
    const FilterConcepto = () => {
        filtro === 'concepto+' ? setFiltro('concepto-') : setFiltro('concepto+')
    }
    const FilterNroControl = () => {
        filtro === 'nrocontrol+' ? setFiltro('nrocontrol-') : setFiltro('nrocontrol+')
    }
    const FilterPeriodo = () => {
        filtro === 'periodo+' ? setFiltro('periodo-') : setFiltro('periodo+')
    }
    const FilterFecPago = () => {
        filtro === 'fecpago+' ? setFiltro('fecpago-') : setFiltro('fecpago+')
    }
    const FilterNroConvenio = () => {
        filtro === 'nroconvenio+' ? setFiltro('nroconvenio-') : setFiltro('nroconvenio+')
    }

    useEffect(() => {
    
        if(respuesta && !error){

            switch(filtro)
            {
                case 'total+':
                    nuevo = respuesta.sort(((a, b) => a.impDepositado - b.impDepositado));
                    setRespuesta(nuevo);
                break;
                case 'total-':
                    nuevo = respuesta.sort(((a, b) => b.impDepositado - a.impDepositado));
                    setRespuesta(nuevo);
                break;
                case 'concepto+':
                    nuevo = respuesta.sort(((a, b) => a.codConcepto - b.codConcepto));
                    setRespuesta(nuevo);
                break;
                case 'concepto-':
                    nuevo = respuesta.sort(((a, b) => b.codConcepto - a.codConcepto));
                    setRespuesta(nuevo);
                break;
                case 'nrocontrol+':
                    nuevo = respuesta.sort(((a, b) => a.nroMovSuc - b.nroMovSuc));
                    setRespuesta(nuevo);
                break;
                case 'nrocontrol-':
                    nuevo = respuesta.sort(((a, b) => b.nroMovSuc - a.nroMovSuc));
                    setRespuesta(nuevo);
                break;
                case 'fecpago+':
                    nuevo = respuesta.sort((a, b) => a.id - b.id);
                    setRespuesta(nuevo);
                break;
                case 'fecpago-':
                    nuevo = respuesta.sort((a, b) => b.id - a.id);
                    setRespuesta(nuevo);
                break;
                case 'nroconvenio+':
                    nuevo = respuesta.sort(((a, b) => a.nroConvenio - b.nroConvenio));
                    setRespuesta(nuevo);
                break;
                case 'nroconvenio-':
                    nuevo = respuesta.sort(((a, b) => b.nroConvenio - a.nroConvenio));
                    setRespuesta(nuevo);
                break;
                case 'periodo+':
                    nuevo = respuesta.sort(((a, b) => a.periodo - b.periodo));
                    setRespuesta(nuevo);
                break;
                case 'periodo-':
                    nuevo = respuesta.sort(((a, b) => b.periodo - a.periodo));
                    setRespuesta(nuevo);
                break;

                default:
            
                break;
            }    
        }
    }, [respuesta, filtro]);
    return(

        <div>
            <h3>Mis Aportes&nbsp;
            {!espera &&
            <svg
            onClick={handleMisAportes}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-eye-fill"
            style={{'cursor':'pointer'}}
            viewBox="0 0 16 16">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
            </svg>}
            &nbsp;
            {!espera &&
            <span
            onClick={handleMisAportes}
            style={{'fontSize':'16px', 'cursor':'pointer'}}>Ver</span>
            }
            </h3>
            <div>
            <div className="row">
                <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
            </div>
            <div className="row">
                <div className="col-md-2 bg-aatrac" style={{'color':'white'}}>Medio de pago&nbsp;<img src={arrowimg} 
                width="15" 
                alt="Ordenar"
                title="Ordenar"
                onClick={FilterNroConvenio}
                style={{'cursor':'pointer'}}/>
                </div>
                <div className="col-md-2 bg-aatrac" style={{'color':'white'}}>Fecha de pago&nbsp;
                <img src={arrowimg} 
                width="15" 
                alt="Ordenar"
                title="Ordenar"
                onClick={FilterFecPago}
                style={{'cursor':'pointer'}}/>
                </div>
                <div className="col-md-1 bg-aatrac" style={{'color':'white'}}>Per&iacute;odo&nbsp;
                <img src={arrowimg} 
                width="15" 
                alt="Ordenar"
                title="Ordenar"
                onClick={FilterPeriodo}
                style={{'cursor':'pointer'}}/>
                </div>
                <div className="col-md-2 bg-aatrac" style={{'color':'white'}}>Nº de control&nbsp;
                <img src={arrowimg} 
                width="15" 
                alt="Ordenar"
                title="Ordenar"
                onClick={FilterNroControl}
                style={{'cursor':'pointer'}}/>
                </div>
                <div className="col-md-4 bg-aatrac" style={{'color':'white'}}>Concepto&nbsp;
                <img src={arrowimg} 
                width="15" 
                alt="Ordenar"
                title="Ordenar"
                onClick={FilterConcepto}
                style={{'cursor':'pointer'}}/>
                </div>
                <div className="col-md-1 bg-aatrac" style={{'color':'white'}}>Pagado&nbsp;
                <img src={arrowimg} 
                width="15" 
                alt="Ordenar"
                title="Ordenar"
                onClick={FilterTotal}
                style={{'cursor':'pointer'}}/>
                </div>
            </div>
            {espera &&
            <LoadingOverlay
            active={true}
            spinner
            text='Cargando respuesta del servidor...'            >
            <p><br/><br/></p><br/><br/><br/>
            </LoadingOverlay>}    
                {error ? <div className="alert alert-danger div-error"> {error}</div> :

            respuesta &&
                respuesta.map((item) => (
                <div className="row" key={item.id}>
                    <div className="col-md-2" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.nroConvenio}</div>
                    <div className="col-md-2" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.fecPago}</div>
                    <div className="col-md-1" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.periodo}</div>
                    <div className="col-md-2" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.nroMovSuc}</div>
                    <div className="col-md-4" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.desConcepto}</div>
                    <div className="col-md-1 text-end" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(item.impDepositado)}</div>
                </div>
                ))}
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/><br/><br/><br/>
        </div>
    );
}