import {useState} from "react";
import interbanking from "../img/ib_pago.png";
import mercadopago from "../img/mp_pago.png";
import pagofacil from "../img/pf_pago.png";
import pagar from "../img/pagar-red_link.png";

export default function Informacion(props) {

    const[espera, setEspera]= useState(false);
    const [respuesta, setRespuesta] = useState([]);
    const [error, setError] = useState(null);


    return (
        <div>
            <h3><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg>&nbsp;Información</h3>
            
            <div className="row">
                <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <br/>
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12"><h4>Información general</h4></div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-12 text-center"><iframe src={`${window.$SERVER_API}docs/general.pdf?v=1.2`} height="690" width="90%" title="Documentación general"></iframe></div>
            </div>
            <br/>
            <hr/>
            <br/><br/>
            <div className="row">
                <div className="col-md-12"><h4>Información sobre pagos en línea</h4></div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-12">
                    <h5>Pagar - Red Link</h5>
                    <img src={pagar} alt="PAGAR - Red Link" width={200}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                <iframe src="https://drive.google.com/file/d/1T9vNaJetNsDKyh-6NiqqdCtbx9CNQQT4/preview" width="640" height="480" allow="autoplay" title="PAGAR - Red Link"></iframe>
                </div>
            </div>
            <hr/>
            <br/>
            <div className="row">
                <div className="col-md-12">
                    <h5>Interbanking - Pagos BtoB</h5>
                    <img src={interbanking} alt="Interbanking" width={200}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                    <iframe src="https://drive.google.com/file/d/1eu-lj8GzrHb7M8RbY5HcrI0d2Uy_1T9x/preview" width="640" height="480" allow="autoplay" title="Interbanking"></iframe>
                </div>
            </div>
            <hr/>
            <br/>
            <div className="row">
                <div className="col-md-12">
                    <h5>Mercado Pago - Pagar con el celular</h5>
                    <img src={mercadopago} alt="Mercado Pago" width={200}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                <iframe src="https://drive.google.com/file/d/1kmTLTXgp_vD7jgN0-AlwylaKpqsBWkyu/preview" width="640" height="480" allow="autoplay" title="Mercado Pago"></iframe>
                </div>
            </div>
            <hr/>
            <br/>
            <div className="row">
                <div className="col-md-12">
                    <h5>Pago Facil - Pagos en línea</h5>
                    <img src={pagofacil} alt="Pago Facil" width={200}/>
                    
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-12 text-center">
                    <iframe src="https://drive.google.com/file/d/1oWEwKRERd90ipMb661ua3FKBkUqXNmi8/preview" width="640" height="480" allow="autoplay"></iframe>
                </div>
            </div>
            <br/>
            <hr/>
            <br/>
            <br/>
            <br/>
            <div className="row">
                <div className="col-md-12 text-secondary">
                    <i>Próximamente se implementarán los medios de pago de la red Banelco</i>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            
        </div>
    );
}