import { useRef } from "react";
import InputMask from "react-input-mask";

export default function CuilInput(props) {
    // textInput must be declared here so the ref can refer to it
    const textInput = useRef(null);
    
  
    return (
        <InputMask 
        mask='99-99999999-9' 
        value={props.value} 
        onChange={props.onChange}
        ref={textInput}
        >
    </InputMask>
          
    );
  }