import {useRef, useState} from "react";
import LoadingOverlay from 'react-loading-overlay-ts';
import swal from 'sweetalert';

export default function MisDatos(props) {

    const [abierto, setAbierto] = useState(false);
    const [modifica, setModifica] = useState(false);

    const calle         = useRef();
    const nro           = useRef();
    const piso          = useRef();
    const dpto          = useRef();
    const localidad     = useRef();
    const cod_postal    = useRef();
    const id_provincia  = useRef();
    const telefono      = useRef();
    const email         = useRef();
    const contacto      = useRef();

    const handlehabi = () =>
    {
        setModifica(true);
        abierto ? setAbierto(false) : setAbierto(true);
    }

    const handlecancela = () =>
    {
        setModifica(false);
        abierto ? setAbierto(false) : setAbierto(true);
    }

    const handlemodifica = () =>
    {
        setModifica(false);
        abierto ? setAbierto(false) : setAbierto(true);

        const data = {
            "usuario"       : window.CUIT,
            "token"         : window.token,
            "calle"         : calle.current.value,
            "nro"           : nro.current.value,
            "piso"          : piso.current.value,
            "dpto"          : dpto.current.value,
            "localidad"     : localidad.current.value,
            "cod_postal"    : cod_postal.current.value,
            "id_provincia"  : id_provincia.current.value,
            "telefono"      : telefono.current.value,
            "email"         : email.current.value,
            "contacto"      : contacto.current.value
            
        };

        fetch(`${window.$SERVER_API}modificadomicilioempresa.php`
        ,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type':'application/json'
                }

            })
            .then((response) => response.json())
            .then((respuesta) => {
                
                if(respuesta.error)
                {
                    swal("Error", respuesta.error, "warning", {
                    button: "Aceptar",
                    });
                }
                else {
                    swal("", "Domicilio actualizado correctamente", "warning", {
                    button: "Aceptar",
                    });             
                }
            });
    }
    const urlMisDatos = `${window.$SERVER_API}misdatos.php`;

    const enviarData = async(url, data)=>{

        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        });
        const json = await resp.json();

        return json;

    }
        const[respuesta, setRespuesta]= useState();
        const[error, setError]= useState(null);
        const[espera, setEspera]= useState(false);

        const handleMisDatos = async()=>{
            
            setRespuesta([]);
            setEspera(true);
            const data = {
            "usuario" : window.CUIT,
            "token"   : window.token
        };

        const respuestaJson =  await enviarData(urlMisDatos, data);

        setRespuesta(respuestaJson);
        setError(respuestaJson.error);
        setEspera(false);
 
    }
    
    return(

        <div>
            <h3>Mis Datos&nbsp;
            {!espera &&
            <svg
            onClick={handleMisDatos}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-eye-fill"
            style={{'cursor':'pointer'}}
            viewBox="0 0 16 16">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
            </svg>}
            &nbsp;
            {!espera &&
            <span
            onClick={handleMisDatos}
            style={{'fontSize':'16px', 'cursor':'pointer'}}>Ver</span>}
            </h3>
            <div>
            <div className="row">
                <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
            </div>
            {espera &&
            <LoadingOverlay
            active={true}
            spinner
            text='Cargando respuesta del servidor...'            >
            <p><br/><br/></p><br/><br/><br/>
            </LoadingOverlay>}
                
                {error ? <div className="alert alert-danger div-error"> {error}</div> :

            respuesta &&
                respuesta.map((item) => (
                    <div key={item.id}>
                    <div className="container bg-aatrac-gris">
                    <br/>
                    <h4>
                    Datos de la emisora</h4>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="cuit">CUIT</label>
                        <input 
                        className="form-control" 
                        id="cuit" 
                        name="cuit" 
                        type="text" 
                        maxLength="13"
                        value={item.cuit}
                        disabled/>
                    </div>
                    <div className="col-md-9">
                        <label htmlFor="desEmpresa">Raz&oacute;n Social</label>
                        <input 
                        className="form-control" 
                        id="desEmpresa" 
                        name="desEmpresa" 
                        type="text" 
                        maxLength="45"
                        value={item.desEmpresa}
                        disabled/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="tipo_alta">Tipo de alta</label>
                        <input 
                        className="form-control" 
                        type="text" 
                        id="tipo_alta"
                        value={item.tipo_alta}
                        disabled/>
                    </div>
                    <div className="col-md-3" id="capa_oficio">
                        <label htmlFor="tipo_alta_oficio">Alta de Oficio</label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="tipo_alta_oficio"
                        value={item.tipo_alta_oficio}
                        disabled/>
                    </div>
                    <div className="col-md-3" id="capa_resnro">
                        <label htmlFor="resnro">Res. Nro. <span style={{'fontSize':'11px'}}>[COMFER/AFSCA/ENACOM]</span></label>
                        <input 
                        className="form-control" 
                        id="resnro" 
                        name="resnro" 
                        type="text" 
                        maxLength="20"
                        value={item.resnro}
                        disabled/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="fecha_resolucion">Fecha Resoluci&oacute;n</label>
                        <input 
                        className="form-control" 
                        id="fecha_resolucion" 
                        name="fecha_resolucion" 
                        type="date"
                        value={item.fecha_resolucion}
                        disabled/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <label htmlFor="nombre_fantasia">Nombre Fantas&iacute;a</label>
                        <input 
                        className="form-control" 
                        id="nombre_fantasia" 
                        name="nombre_fantasia" 
                        type="text" 
                        maxLength="45"
                        value={item.nombre_fantasia}
                        disabled/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="senal_distintiva">Señal Distintiva</label>
                        <input
                        className="form-control" 
                        id="senal_distintiva" 
                        name="senal_distintiva" 
                        type="text" 
                        maxLength="10"
                        value={item.senal_distintiva}
                        disabled/>
                    </div>
                </div>
                <div className="row" id="capa_no_otros_2">
                    <div className="col-md-4">
                        <label htmlFor="tipo_emision">Tipo emisi&oacute;n</label>
                        <input 
                        type="text"
                        className="form-control"  
                        id="tipo_emision"
                        value={item.tipo_emision}
                        disabled/>
                    </div>
                    <div className="col-md-4">
                    <label htmlFor="categoria">Categoría</label>
                        <input 
                        type="text" 
                        className="form-control" 
                        name="categoria" 
                        id="categoria"
                        value={item.categoria}
                        disabled/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="frecuencia">Frecuencia</label>
                        <input 
                        className="form-control" 
                        id="frecuencia" 
                        name="frecuencia" 
                        type="text" 
                        maxLength="7"
                        value={item.frecuencia}
                        disabled/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                    <label htmlFor="fec_ini_activ">Fecha de inicio de actividades</label>
                        <input 
                        className="form-control" 
                        id="fec_ini_activ" 
                        name="fec_ini_activ" 
                        type="date" 
                        value={item.fec_ini_activ}
                        disabled/>
                    </div>
                    <div className="col-md-8 text-secondary">
                        <br/><i>Es importante informar la fecha de inicio de actividades ya que está vinculada a la consulta de deuda</i> 
                    </div>
                </div>
                <br/>
                </div>
                <div className="container bg-aatrac-gris">
                <br/>
                <h4>
                    Domicilio
                    {!modifica &&
                    <svg
                    onClick={handlehabi}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    style={{ 'cursor': 'pointer' }}
                    fill="currentColor"
                    className="bi bi-arrow-counterclockwise"
                    viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                    <title>Modificar</title>
                    </svg>}
                </h4>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="calle">Calle</label>
                        <input 
                        className="form-control" 
                        id="calle"
                        ref={calle} 
                        type="text" 
                        maxLength="30"
                        defaultValue={item.calle}
                        disabled={!abierto}/>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="nro">N&deg; de calle</label>
                        <input 
                        className="form-control" 
                        ref={nro} 
                        type="number" 
                        min="0" 
                        max="99999"
                        defaultValue={item.nro}
                        disabled={!abierto}/>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="piso">Piso</label>
                        <input 
                        className="form-control" 
                        ref={piso} 
                        type="text" 
                        maxLength="3"
                        defaultValue={item.piso}
                        disabled={!abierto}/>
                    </div>
                    <div className="col-md-2">
                        <label htmlFor="dpto">Departamento</label>
                        <input 
                        className="form-control" 
                        ref={dpto} 
                        type="text" 
                        maxLength="3"
                        defaultValue={item.dpto}
                        disabled={!abierto}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="localidad">Localidad</label>
                        <input 
                        className="form-control" 
                        ref={localidad} 
                        type="text" 
                        maxLength="45"
                        defaultValue={item.localidad}
                        disabled={!abierto}/>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="id_provincia">Provincia</label>
                        <select 
                        className="form-control" 
                        ref={id_provincia} 
                        disabled={!abierto}>
                            {item.provincias.map((ele) => (
                            <option key={ele.id_provincia} value={ele.id_provincia}>{ele.descripcion}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="cod_postal">C&oacute;digo Postal</label>
                        <input 
                        className="form-control" 
                        ref={cod_postal} 
                        type="number" 
                        min="1000" 
                        max="9999"
                        defaultValue={item.cod_postal}
                        disabled={!abierto}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="telefono">Tel&eacute;fono</label>
                        <input 
                        className="form-control" 
                        ref={telefono} 
                        type="tel" 
                        maxLength="15"
                        defaultValue={item.telefono}
                        disabled={!abierto}/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="email">email</label>
                        <input 
                        className="form-control" 
                        ref={email} 
                        type="email" 
                        maxLength="45"
                        defaultValue={item.email}
                        disabled={!abierto}/>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="contacto">Persona de Contacto</label>
                        <input 
                        className="form-control" 
                        ref={contacto} 
                        type="text" 
                        maxLength="45"
                        defaultValue={item.contacto}
                        disabled={!abierto}/>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-lg-12 text-center">
                    {abierto && <>
                        <button
                        className="btn btn-light"
                        onClick={handlecancela}>
                        Cancelar
                        </button>
                        <button 
                        type="button" 
                        className="btn btn-danger btn-sm"
                        onClick={handlemodifica}
                        >Guardar cambios</button>
                        </>}
                    </div>
                </div>
                </div>
            </div>
                ))}
            
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/><br/><br/><br/><br/>
            <br/>
        </div>
    </div>
    );
}