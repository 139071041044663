import {useState, useEffect} from "react";
import LoadingOverlay from 'react-loading-overlay-ts';
import swal from 'sweetalert';
import botonrojo from '../img/punto_rojo.jpg';
import botonamarillo from '../img/punto_amarillo.jpg';


export default function ConsultarDeuda(props) {
    
    window.nroPlanilla = false;
    window.totalpagarboletagenerada = false;

    const urlConsultarDeuda = `${window.$SERVER_API}consultardeuda.php`;

    const enviarData = async(url, data)=>{

        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        });
        const json = await resp.json();

        return json;

    }

        const[respuesta, setRespuesta]= useState();
        const[error, setError]= useState(null);
        const[espera, setEspera]= useState(false);
        const[totalIntereses,setTotalIntereses] = useState(0);
        

 const handleConsultarDeuda = async()=>{
    
    setTotalIntereses(0);
    setRespuesta([]);    
    setEspera(true);
    setError(false);
    
        const data = {
            "usuario" : window.CUIT,
            "token"   : window.token
        };

        const respuestaJson =  await enviarData(urlConsultarDeuda, data);
        setRespuesta(respuestaJson);
        
        setError(respuestaJson.error);
        if(respuestaJson.error === undefined)
        {
            const obtenerTotales = array => array.reduce((acumulador, { interes_total}) => parseFloat(acumulador) + parseFloat(interes_total), 0);
            window.id_recargo_empresa = respuestaJson[0].id_recargo_empresa;
            window.ult_fec_pago = respuestaJson[0].ult_fec_pago;
            setTotalIntereses(Math.round(obtenerTotales(respuestaJson)*100)/100);
            
        }
        
        setEspera(false);
         
    
    }

    const handleGenerarBoleta = () => {
        
        swal({
            title: "¿Está seguro?",
            text: "La boleta deberá ser abonada con un plazo máximo de hasta dos días hábiles luego de su confección. En caso contrario, deberá volver a generarla.",
            icon: "warning",
            buttons: ["Cancelar", "Generar Boleta"],
            dangerMode: true,
          })
          .then((willGenerate) => {
            if (willGenerate) {
                
                window.totalpagarboletagenerada = totalIntereses;
                props.operar('GenerarBoleta');
                props.pagarintereses(true);
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                
            } 
          });
    }

    
    return(
        <div>
            <h3>Consultar Deuda&nbsp;
            
            {!espera &&
            <svg
            onClick={handleConsultarDeuda}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-eye-fill"
            style={{'cursor':'pointer'}}
            viewBox="0 0 16 16">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
            </svg>}
            &nbsp;
            {!espera &&
            <span
            onClick={handleConsultarDeuda}
            style={{'fontSize':'16px', 'cursor':'pointer'}}>Ver</span>
            }
            <span style={{'fontSize':'14px','paddingLeft':'50px'}}>Concepto: Aporte sindical 2,5%</span></h3>
            
            <div>
            <div className="row">
                <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
            </div>
        
                
                {error ? <div className="alert alert-danger div-error"> {error}</div> : ''}
{respuesta && !error &&
            <div className="row">
                <div className="col-md-1">Período</div>
                <div className="col-md-1">Fec.Pago</div>
                <div className="col-md-1">Depositado</div>
                <div className="col-md-1">Interés pagado</div>
                <div className="col-md-1">Interés sistema</div>
                <div className="col-md-1">Interés adeudado</div>
                <div className="col-md-2">Interés adeudado a la fecha</div>
                <div className="col-md-4">Planilla</div>
            </div>}
            {espera &&
            <LoadingOverlay
            active={true}
            spinner
            text='Esta consulta puede demorar hasta 30 segundos...'            >
            <p><br/><br/></p><br/><br/><br/>
            <p></p>
            </LoadingOverlay>}
            {respuesta && !error &&
            respuesta.map((item) => (
            <div className="row" key={item.id}>
                <div className="col-md-1" style={item.id%2===0 ? {'backgroundColor':item.color} : {'backgroundColor':item.color} }>{item.periodo}</div>
                <div className="col-md-1" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.fecPago}</div>
                <div className="col-md-1 text-end" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(item.impDepositado)}</div>
                <div className="col-md-1 text-end" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(item.interes_pagado)}</div>
                <div className="col-md-1 text-end" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(item.interes_sistema)}</div>
                <div className="col-md-1 text-end" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(item.interes_adeudado)}</div>
                <div className="col-md-2 text-end" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(item.interes_total)}</div>
                <div className="col-md-4 text-end" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }><a href={`${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${item.nroPlanilla}`} target="_blank" title="Ver" rel="noreferrer">{item.nroPlanilla}</a></div>
            </div>
                 
            ))}
            {respuesta && !error && !espera && totalIntereses > 0 &&<>
            <div className="row">
                <div className="col-md-12 text-end">&nbsp;</div>
            </div>
            <div className="row">
                <div className="col-md-8 text-end"><h4>Total de intereses adeudados: {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(totalIntereses)}</h4></div>
                <div className="col-md-4"><button
                        className="btn btn-danger"
                        onClick={handleGenerarBoleta}
                        disabled={espera}
                        >
                        Generar Boleta
                    </button></div>
                </div></>}
            </div>
            {respuesta && !error && !espera &&<>
            <div className="row">
                <div className="col-md-12">
                    <br/><br/><br/>
                    <h6>El siguiente informe muestra su deuda y no implica que no exista deuda de períodos anteriores</h6>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <small><i><img src={botonrojo} alt="" width="12"/>&nbsp;Los valores resaltados en rojo indican una deuda de período vencido no pagado el cual debe ser impreso y abonado como con los demás períodos</i></small>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                <small><i><img src={botonamarillo} alt="" width="12"/>&nbsp;Los valores resaltados en amarillo indican una deuda de intereses, ya que la boleta ha sido abonada con posterioridad a la fecha de vencimiento del período</i></small>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">
                <h6><br/>Deuda actualizada al {window.ult_fec_pago} S.E.U.O. (Salvo error u omisión) - Sujeto a Verificación</h6>
                </div>
            </div> </>}
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/><br/><br/><br/><br/><br/>
        </div>
    );
}