import {useRef, useState, useEffect} from "react";
import swal from 'sweetalert';

export default function Registration(props) {

    const handleExisteCUIT = () => 
    {
        if(CUIT.current.value.length == 11)
        {
            const data = {
                "usuario"   : CUIT.current.value
            };

            fetch(`${window.$SERVER_API}existeempresa.php`
                ,
                    {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type':'application/json'
                        }

                    })
                    .then((response) => response.json())
                    .then((respuesta) => {
                        
                        //alert(JSON.stringify(respuesta))
                        if(respuesta.existe)
                        {
                            swal("Aviso", "La empresa cuya CUIT es " + CUIT.current.value + " ya se encuentra registrada. Deberá ingresar con su CUIT y clave. Si olvidó su clave, deberá solicitar un blanqueo de la misma a: soporte.aatrac@gmail.com", "warning", {
                                button: "Aceptar",
                            });
                            window.scroll({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                            props.acceder(false);
                            props.ingresar(false);
                            props.registrar(false);
                        }
                    
                        
                    });
        
        }
    }
    
    const [provincia, setProvincia] = useState([]);

    const CUIT              = useRef();
    const desEmpresa        = useRef();
    const tipo_alta         = useRef();
    const tipo_alta_oficio  = useRef();
    const resnro            = useRef();
    const fecha_resolucion  = useRef();
    const nombre_fantasia   = useRef(); 
    const senal_distintiva  = useRef(); 
    const tipo_emision      = useRef();
    const categoria         = useRef(); 
    const frecuencia        = useRef();
    const calle             = useRef();
    const nro               = useRef();
    const piso              = useRef();
    const dpto              = useRef();
    const localidad         = useRef();
    const id_provincia      = useRef();
    const cod_postal        = useRef();
    const telefono          = useRef();
    const email             = useRef();
    const contacto          = useRef();
    
    const clave             = useRef();
    const claveconfirma     = useRef();
    
    const[respuesta, setRespuesta]  = useState();
    const[error, setError]          = useState(null);
    const[espera, setEspera]        = useState(false);

    const[isAM, setIsAM]            = useState(false);
    const[isFM, setIsFM]            = useState(false);
    const[isWEB, setIsWEB]          = useState(false);

    const handleProvincia = () => {
        fetch(`${window.$SERVER_API}provincia.php`
    ,
        {
            method: 'POST',
            body: JSON.stringify({"usuario" : 'provincia'}),
            headers: {
                'Content-Type':'application/json'
            }

        })
        .then((response) => response.json())
        .then((respuestaJson) => {
            
            if(!respuestaJson.error)
            {
                setProvincia(respuestaJson);
                setError(respuestaJson.error);
                setEspera(false);
            }
            else {
              setError(respuestaJson.error);
              setEspera(false);
            }
        
        });

    }
        
    const handleTipoEmision = () => {
        switch (tipo_emision.current.value) {
            case "AM":
                setIsAM(true);
                setIsFM(false);
                setIsWEB(false);
            break;
            case "FM":
                setIsAM(false);
                setIsFM(true);
                setIsWEB(false);
            break;
            case "WEB":
                setIsAM(false);
                setIsFM(false);
                setIsWEB(true);
            break;
        
            default:
                setIsAM(false);
                setIsFM(false);
                setIsWEB(false);
            break;
        }
    }

    const handleLogin = () => {
        
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        props.acceder(false);
        props.ingresar(false);
        props.registrar(false);
    }

  const SECURITY_CODE_LENGTH = 4;
  const [values, setValues] = useState({ securityCode: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [securityCode, setSecurityCode] = useState("");

  const generateSecurityCodeImage = () => {
    const code = Array.from(Array(SECURITY_CODE_LENGTH), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join("");

    const securityCodeImageElement = document.getElementById(
      "securityCodeImage"
    );
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 50;
    canvas.height = 20;

    const imgElement = document.createElement("img");

    imgElement.addEventListener("load", loadImage);
    imgElement.src = `data:image/svg+xml,${encodeURIComponent(
      `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="100"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml" style="display:block;"><span style="margin:auto;">${code}</span></div></foreignObject></svg>`
    )}`;

    function loadImage(e) {
      ctx.drawImage(e.target, 0, 0);
      securityCodeImageElement.src = canvas.toDataURL();
    }

    setSecurityCode(code);
  };

  const onChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    event.persist();
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (event) => {
    
    
    setIsSubmitting(true);

    if (event) event.preventDefault();

    setTimeout(() => {
      if (values.securityCode !== securityCode) {
        setError("Código de seguridad incorrecto");
        generateSecurityCodeImage();
        setIsSubmitting(false);
        return;
    }
    
    setEspera(true);
    setError(false);

    const data = {
        "usuario"           : CUIT.current.value,
        "desEmpresa"        : desEmpresa.current.value,
        "tipo_alta"         : tipo_alta.current.value,
        "tipo_alta_oficio"  : tipo_alta_oficio.current.value,
        "resnro"            : resnro.current.value,
        "fecha_resolucion"  : fecha_resolucion.current.value,
        "nombre_fantasia"   : nombre_fantasia.current.value,
        "senal_distintiva"  : senal_distintiva.current.value,
        "tipo_emision"      : tipo_emision.current.value,
        "categoria"         : categoria.current.value,
        "frecuencia"        : frecuencia.current.value,
        "calle"             : calle.current.value,
        "nro"               : nro.current.value,
        "piso"              : piso.current.value,
        "dpto"              : dpto.current.value,
        "localidad"         : localidad.current.value,
        "id_provincia"      : id_provincia.current.value,
        "cod_postal"        : cod_postal.current.value,
        "telefono"          : telefono.current.value,
        "email"             : email.current.value,
        "contacto"          : contacto.current.value,
        "clave"             : clave.current.value,
        "claveconfirma"     : claveconfirma.current.value
        
    };

    fetch(`${window.$SERVER_API}registro.php`
    ,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        })
        .then((response) => response.json())
        .then((respuestaJson) => {
            
            if(!respuestaJson.error)
            {
                window.CUIT  = null;
                window.token = null;
                props.acceder(false);
                props.ingresar(false);
                props.registrar(false);
                
                
            swal("Aviso", respuestaJson.respuesta, "info", {
                button: "Aceptar",
                }); 
            
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                  });
                
            }
            else {
              setError(respuestaJson.error);
              setEspera(false);setIsSubmitting(false);
            }
        
        });  
    
    
    
      //alert("Easy peasy lemon squeeze");
      
      //generateSecurityCodeImage();
      
    }, 1000);
  };

  useEffect(() => {
    generateSecurityCodeImage();
  }, []);

    return(
        <div><form onSubmit={onSubmit}>
            <br/><br/><br/><br/><br/><br/>
            <h3><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-pen-fill" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z"/>
</svg>&nbsp;Registrarse&nbsp;</h3>
            <div>
                <div className="row">
                    <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
                </div>
                      
                <div className="container bg-aatrac-gris">
                    
                    <br/>
                    <h4>Datos de la emisora</h4>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="cuit">CUIT (sin guiones)</label>
                            <input 
                            className="form-control" 
                            ref={CUIT}
                            onChange={handleExisteCUIT}
                            type="text" 
                            maxLength="11"
                            autoComplete="off"
                            required/>
                        </div>
                        <div className="col-md-9">
                            <label htmlFor="desEmpresa">Raz&oacute;n Social</label>
                            <input 
                            className="form-control" 
                            ref={desEmpresa}
                            type="text" 
                            maxLength="45"
                            required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <label htmlFor="tipo_alta">Tipo de alta</label>
                            <input 
                            className="form-control"
                            ref={tipo_alta} 
                            type="text" 
                            defaultValue="Voluntario"
                            disabled/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="tipo_alta_oficio">Alta de Oficio</label>
                            <input 
                            type="text" 
                            className="form-control" 
                            ref={tipo_alta_oficio}
                            defaultValue="NO"
                            disabled/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="resnro">Res. Nro. <span style={{'fontSize':'11px'}}>[COMFER/AFSCA/ENACOM]</span></label>
                            <input 
                            className="form-control"
                            ref={resnro} 
                            type="text" 
                            maxLength="20"/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="fecha_resolucion">Fecha Resoluci&oacute;n</label>
                            <input 
                            className="form-control" 
                            ref={fecha_resolucion}
                            type="date"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <label htmlFor="nombre_fantasia">Nombre Fantas&iacute;a</label>
                            <input 
                            className="form-control" 
                            ref={nombre_fantasia} 
                            type="text" 
                            maxLength="45"
                            required/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="senal_distintiva">Señal Distintiva</label>
                            <input
                            className="form-control" 
                            ref={senal_distintiva} 
                            type="text" 
                            maxLength="10"
                            required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="tipo_emision">Tipo emisi&oacute;n</label>
                            <select 
                            className="form-control"  
                            ref={tipo_emision}
                            required
                            onChange={handleTipoEmision}>
                                <option value="">Seleccionar</option>
                                <option value="AM">AM</option>
                                <option value="FM">FM</option>
                                <option value="WEB">WEB</option>
                            </select>
                        </div>
                        <div className="col-md-4">
                            {isAM && <>
                            <label htmlFor="categoria">Categoría</label>
                                <select 
                                type="text" 
                                className="form-control" 
                                ref={categoria}
                                required>
                                    <option value="">Seleccionar</option>
                                    <option value="1">I</option>
                                    <option value="2">II</option>
                                    <option value="3">III</option>
                                    <option value="4">IV</option>
                                    <option value="5">V</option>
                                    <option value="6">VI</option>
                                    <option value="7">VII</option>
                                </select>
                            </>}
                            {isFM && <>
                            <label htmlFor="categoria">Categoría</label>
                                <select 
                                type="text" 
                                className="form-control" 
                                ref={categoria}>
                                    <option value="">Seleccionar</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                    <option value="E">E</option>
                                    <option value="F">F</option>
                                    <option value="G">G</option>
                                </select>
                            </>}
                            {isWEB && <>
                            <label htmlFor="categoria">Categoría</label>
                                <select 
                                type="text" 
                                className="form-control" 
                                ref={categoria}
                                disabled>
                                    <option value="W">WEB</option>
                                </select>
                            </>}
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="frecuencia">Frecuencia</label>
                            <input 
                            className="form-control"
                            ref={frecuencia} 
                            type="text" 
                            maxLength="7"/>
                        </div>
                    </div>
                    <br/>
                </div>
                <div className="container bg-aatrac-gris">
                    <br/>
                    <h4>Domicilio</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="calle">Calle</label>
                            <input 
                            className="form-control" 
                            ref={calle} 
                            type="text" 
                            maxLength="30"
                            required/>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="nro">N&deg; de calle</label>
                            <input 
                            className="form-control" 
                            ref={nro} 
                            type="number" 
                            min="0" 
                            max="99999"
                            required/>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="piso">Piso</label>
                            <input 
                            className="form-control" 
                            ref={piso} 
                            type="text" 
                            maxLength="3"/>
                        </div>
                        <div className="col-md-2">
                            <label htmlFor="dpto">Departamento</label>
                            <input 
                            className="form-control" 
                            ref={dpto} 
                            type="text" 
                            maxLength="3"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="localidad">Localidad</label>
                            <input 
                            className="form-control" 
                            ref={localidad} 
                            type="text" 
                            maxLength="45"
                            required/>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="id_provincia">Provincia</label>
                                <select 
                                onClick={handleProvincia}
                                className="form-control" 
                                ref={id_provincia}
                                required>
                                    <option value="">Seleccionar</option>
                                 {
                                provincia.map(e =>
                                    <option key={e.id} value={e.id_provincia}>{e.descripcion}</option>
                                    )
                                }
                                </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="cod_postal">C&oacute;digo Postal</label>
                            <input 
                            className="form-control" 
                            ref={cod_postal} 
                            type="number" 
                            min="1000" 
                            max="9999"
                            required/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="telefono">Tel&eacute;fono</label>
                            <input 
                            className="form-control" 
                            ref={telefono} 
                            type="tel" 
                            maxLength="15"
                            required/>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="email">email</label>
                            <input 
                            className="form-control" 
                            ref={email} 
                            type="email" 
                            maxLength="45"
                            required/>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="contacto">Persona de Contacto</label>
                            <input 
                            className="form-control" 
                            ref={contacto} 
                            type="text" 
                            maxLength="45"
                            required/>
                        </div>
                    </div>
                </div>
                <div className="container bg-aatrac-gris">
                    <br/>
                    <h4>Datos de acceso</h4>
                    <div className="row"><div className="col-md-12">La clave debe tener 8 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula. No puede tener otros símbolos.<br/><br/><br/></div></div>
                    <div className="row">
                        <div className="col-md-2">
                        <label htmlFor="clave">Clave de 8 caracteres</label>
                            <input 
                            className="form-control" 
                            ref={clave} 
                            type="password" 
                            maxLength="8"
                            required/>
                        </div>
                        <div className="col-md-2">
                        <label htmlFor="clave">Confirma clave</label>
                            <input 
                            className="form-control" 
                            ref={claveconfirma} 
                            type="password" 
                            maxLength="8"
                            required/>
                        </div>
                        <div className="col-md-4">
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "66.6%"}}>
                                    <label htmlFor="securityCode" style={{ display: "inline-block" }}>
                                    Código de seguridad
                                    </label>
                                    <input
                                    id="securityCode"
                                    style={{ flexGrow: 1, width: "100%", height: "3rem" }}
                                    type="text"
                                    name="securityCode"
                                    required
                                    onChange={onChange}
                                    autoComplete={"off"}
                                    value={values.securityCode}/>
                                </div>
                                <div style={{ width: "33.3%", paddingTop: "1.2rem" }}>
                                    <img
                                    id="securityCodeImage"
                                    alt="Security Code Challange"
                                    style={{
                                    objectFit: "contain",
                                    width: "100%",
                                    height: "100%",
                                    paddingLeft: ".5rem",
                                    paddingRight: ".5rem"
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                {error &&
                    <div className="row">
                        <div style={{ marginTop: "0.2rem" }} className="col-md-12 alert alert-danger text-center">{error}</div>
                    </div>}
                    <br/>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <button
                            type="reset"
                            className="btn btn-light"
                            onClick={handleLogin}>
                            Cancelar
                            </button>
                            <button 
                            className="btn btn-danger btn-sm"
                            type="submit"
                            disabled={isSubmitting}>
                                Registrarse
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </form>
            <br/>
            <br/>
            <br/><br/><br/><br/>
            <br/>
        </div>
    )
}