import {useState, useEffect} from 'react';
import './App.css';
import Footer from './componentes/Footer';
import ContainerLogged from './componentes/ContainerLogged';
import ContainerNoLogged from './componentes/ContainerNoLogged';
import NavBarLogged from './componentes/NavBarLogged';
import NavBarNoLogged from './componentes/NavBarNoLogged';
import Registration from './componentes/Registration';
import nointernet from '../src/img/no-internet.gif'
import IngresarSinClave from './componentes/IngresarSinClave';
import NoMobile from './componentes/NoMobile';
import Contacto from './componentes/Contacto';


function App() {

  const [usamovil, setUsaMovil] = useState();
  
  useEffect(() => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
            //console.log("Estás usando un dispositivo móvil!!");
            setUsaMovil(true);
        } else {
            //console.log("No estás usando un móvil");
            setUsaMovil(false);
        }
      }, []);
  
  const [conectado, setConectado] = useState(false);
  const acceder=(estado)=>{
    setConectado(estado)
  }
    const [registro, setRegistro] = useState(false);
    const registrar=(estado)=>{
      setRegistro(estado);  

  }

  const [contacto, setContacto] = useState(false);
    const contactar=(estado)=>{
      setContacto(estado);  

  }

  const [ingreso, setIngreso] = useState(false);
    const ingresar=(estado)=>{
      setIngreso(estado);  

  }

  const [informa, setInforma] = useState(false);
    const informar=(estado)=>{
      setInforma(estado);  

  }

  const [consulta, setConsulta] = useState(false);
    const consultar=(estado)=>{
      setConsulta(estado);  

  }
  
  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };

  }, [isOnline]);

  
  return (
    <div className="App">
      {usamovil &&
      <NoMobile/>}

      {!usamovil && 
      <><div className='container'>
       
      {conectado ? <NavBarLogged acceder={acceder} ingresar={ingresar} informar={informar} consultar={consultar} consulta={consulta}/> : <NavBarNoLogged registrar={registrar} contactar={contactar} /> }
      
      {isOnline ? (
      conectado ? <ContainerLogged acceder={acceder} ingresar={ingresar} informar={informar} informa={informa} consultar={consultar} consulta={consulta} /> : 
      registro ? <Registration acceder={acceder} ingresar={ingresar} registrar={registrar} /> :
      contacto ? <Contacto acceder={acceder} ingresar={ingresar} registrar={registrar} contactar={contactar} /> :
      ingreso ? <IngresarSinClave acceder={acceder} ingresar={ingresar} registrar={registrar} informar={informar} consultar={consultar} consulta={consulta}/> : 
      <ContainerNoLogged acceder={acceder} registrar={registrar} ingresar={ingresar} contactar={contactar} /> 
      ) : (
      <div>
        <br/><br/><br/><br/><br/><br/>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <h3>Sin internet. Vuelva a intentar en unos minutos.</h3>
          <br/>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 text-center'>
          <img className="img-fluid rounded" src={nointernet} width="150" alt="" />
        </div>
      </div>
      <br/><br/><br/><br/><br/>
      </div>) }
      
      </div><Footer/></>}
      
    </div>
  );
}

export default App;