import { useEffect, useState } from "react";
import img from "../img/logo_aatrac.png";
import campana_false from "../img/campana_false.png";
import campana_true from "../img/campana_true.png";
import campana_false_light from "../img/campana_false_light.png";
import campana_true_light from "../img/campana_true_light.png";
import campana_false_light_mini from "../img/campana_false_light_mini.png";
import swal from 'sweetalert';

export default function NavBarLogged(props) {
    
    const [imagen, setImagen]               = useState(campana_false);
    const [alerta, setAlerta]               = useState(false);
    const [marActualiza, setMarActualiza]   = useState(false);
    //const [actualizar, setActualizar]       = useState(false);
    const [tituloMensaje, setTituloMensaje] = useState(null);
    const [mensaje, setMensaje]             = useState(null);
    
    
    const data = {
        "usuario"               : window.CUIT,
        "token"                 : window.token,
        "id_alerta"             : window.id_alerta
                
    };
    
             
        
        const CargarNotificacion = () => {
            // Código de la función

            //Buscar en la BBDD si hay actualización
            fetch(`${window.$SERVER_API}alertas.php`
        ,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type':'application/json'
                }

            })
            .then((response) => response.json())
            .then((respuesta) => {
                if(!respuesta.error)
                {
                    window.id_alerta = respuesta.id_alerta;
                    setImagen(campana_true);
                    setAlerta(true);
                    setTituloMensaje(respuesta.titulo);
                    setMensaje(respuesta.texto);
                    if(respuesta.marActualizacion == 1)
                        setMarActualiza(true);
                    
                }
                else
                {
                    setAlerta(false);
                    setImagen(campana_false);
                    
                }
                
            });

            //ó alguna notificación
            
        };
    // Tu función a ejecutar
    const Visto = () => {
        fetch(`${window.$SERVER_API}alerta_recepcion.php`
    ,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        })
        .then((response) => response.json())
        .then((respuesta) => {
            if(!respuesta.error)
            {
                setAlerta(false);
                setImagen(campana_false);
                window.id_alerta = respuesta.id_alerta;
            }
            else
            {
                setAlerta(false);
                setImagen(campana_false);
            }
            
        });
    }

    const handleInformacion = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        
        props.consultar(false);
        props.informar(true);
        
    };

    
    const handleConsultas = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        
        props.informar(false);
        props.consultar(true);
        
    };
  
    const handleAlertas = () => {
    
        if(alerta)
        {
            swal({
                title: tituloMensaje,
                text: mensaje,
                icon: campana_false_light_mini,
                button: "Aceptar"
              }).then(() => {
              Visto();
              if(marActualiza)
                window.location.reload(true);
                        
              
              });
        
        }
        if(!alerta)
        {
            swal({
                title: 'Alertas',
                text:'Sin información',
                icon: campana_false_light_mini,
                button: "Aceptar"
              });
        }
    };

    const handleLogOff = () => {
        window.CUIT  = null;
        window.token = null;
        props.acceder(false);
        props.ingresar(false);
    };

    const formatCuit = () => {
        if (!/_/.test(window.CUIT)) {
            return window.CUIT.replace(/(\d{2})(\d{8})(\d{1})/, "$1_$2_$3");
          }
    }
    
    const cambiarImagen = () => {
        if(!alerta){
            if(imagen === campana_false)
                setImagen(campana_false_light);
            else
                setImagen(campana_false);
        }
        if(alerta){
            if(imagen === campana_true)
                setImagen(campana_true_light);
            else
                setImagen(campana_true);
        }
    };

    useEffect(() => {
            
        // Llamada a la función al montar el componente
        
        CargarNotificacion();
              
      
    },[]); // El segundo parámetro es un array de dependencias, [] significa que se ejecutará solo una vez al montar
  

        return(
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark">
            <div className="container">
                
                <div className="col-md-2"><img src={img} alt=""/></div>
                <div className="col-md-4 navbar-brand"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-workspace" viewBox="0 0 16 16">
  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
  <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
</svg>&nbsp;Bienvenido,&nbsp;{formatCuit(window.CUIT) } 
<br/>&nbsp;&nbsp;&nbsp;&nbsp;<span className='sm2'>{window.desEmpresa}</span></div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{'textAlign':'left'}}>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item"
                        style={{'cursor':'pointer'}}
                        onClick={handleInformacion}>
                            <a className="nav-link" href={void(0)} rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg>&nbsp;Información</a></li>
                        <li className="nav-item"
                        style={{'cursor':'pointer'}}
                        onClick={handleConsultas}>
                            <a className="nav-link" href={void(0)} rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
</svg>&nbsp;Consultas</a></li>
                        <li className="nav-item"
                        style={{'cursor':'pointer'}}
                        onClick={handleAlertas}
                        >
                            <a className="nav-link" 
                            href={void(0)} 
                            rel="noreferrer"
                            onMouseEnter={() => cambiarImagen()}
                            onMouseLeave={() => cambiarImagen()}
                            >
                                
                                
                                <img src={imagen} 
                                width={24} 
                                alt=""
                                 />
                                &nbsp;Alertas</a></li>
                        <li className="nav-item"
                        style={{'cursor':'pointer'}}
                        onClick={handleLogOff}><a className="nav-link" href="." rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
  <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
</svg>&nbsp;Salir</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}