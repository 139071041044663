import { useEffect, useState, useRef } from "react";
import swal from 'sweetalert';

export default function CambiarClave(props){

  const clave         = useRef();
  const clavenueva    = useRef();
  const claveconfirma = useRef();

  const[error, setError]          = useState(null);
  
  const handleBorrarError = () => {
    setError(false);
  }

  const SECURITY_CODE_LENGTH = 4;
  const [values, setValues] = useState({ securityCode: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [securityCode, setSecurityCode] = useState("");

  const generateSecurityCodeImage = () => {
    const code = Array.from(Array(SECURITY_CODE_LENGTH), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join("");

    const securityCodeImageElement = document.getElementById(
      "securityCodeImage"
    );
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 50;
    canvas.height = 20;

    const imgElement = document.createElement("img");

    imgElement.addEventListener("load", loadImage);
    imgElement.src = `data:image/svg+xml,${encodeURIComponent(
      `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="100"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml" style="display:block;"><span style="margin:auto;">${code}</span></div></foreignObject></svg>`
    )}`;

    function loadImage(e) {
      ctx.drawImage(e.target, 0, 0);
      securityCodeImageElement.src = canvas.toDataURL();
    }

    setSecurityCode(code);
  };

  const onChange = (event) => {
    
    setError(false);
    const { target } = event;
    const { name, value } = target;

    event.persist();
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (event) => {
    setError(false);
    setIsSubmitting(true);

    if (event) event.preventDefault();

    setTimeout(() => {
      if (values.securityCode !== securityCode) {
        setError("Código de seguridad incorrecto");
        generateSecurityCodeImage();
        setIsSubmitting(false);
        return;
    }

    const data = {
        "usuario"       : window.CUIT,
        "token"         : window.token,
        "clave"         : clave.current.value,
        "clavenueva"    : clavenueva.current.value,
        "claveconfirma" : claveconfirma.current.value
        
    };

    
    fetch(`${window.$SERVER_API}cambiarclave.php`
    ,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        })
        .then((response) => response.json())
        .then((respuestaJson) => {
            
            if(!respuestaJson.error)
            {
              
              
              swal("Aviso", respuestaJson[0].respuesta, "info", {
                button: "Aceptar",
                }); 
              
                window.CUIT  = null;
                window.token = null;
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
              });
                props.acceder(false);
                props.ingresar(false);
                   
            }
            else {
              setError(respuestaJson.error);
              
            }
        
        });
      
      
      setIsSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    generateSecurityCodeImage();
  }, []);

    return (
    <><form onSubmit={onSubmit}>
    <div>
    
      <div className="row">
        <div className="col-md-12">
          <h3>
              Cambiar clave&nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-lock2" viewBox="0 0 16 16">
              <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z"/>
              <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
              </svg>
          </h3>
        </div>
      </div>
      
            </div>
            
      <div className="row">
        <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col-md-12">La clave debe tener 8 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula. No puede tener otros símbolos.<br/><br/><br/></div>
      </div><div className="container bg-aatrac-gris">
      <div className="row">
        <div className="col-md-2">&nbsp;</div>
        <div className="col-md-2">
          <label htmlFor="clave"><br/>Clave actual</label>
            <input 
            className="form-control" 
            ref={clave} 
            onChange={handleBorrarError}
            type="password" 
            maxLength="8"
            required/>
        </div>
        <div className="col-md-2">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col-md-2">&nbsp;</div>
        <div className="col-md-2">
        <label htmlFor="clavenueva">Clave nueva</label>
            <input 
            className="form-control" 
            ref={clavenueva} 
            onChange={handleBorrarError}
            type="password" 
            maxLength="8"
            required/>
        </div>
        <div className="col-md-2">
        <label htmlFor="claveconfirma">Confirma clave</label>
            <input 
            className="form-control" 
            ref={claveconfirma}
            onChange={handleBorrarError} 
            type="password"
            maxLength="8"
            required/>
        </div>
              </div>
              <div className="row">
                <div className="col-md-2">&nbsp;</div>
                    <div className="col-md-4">
                      <div style={{ display: "flex" }}>
                          <div style={{ width: "66.6%"}}>
                              <label htmlFor="securityCode" style={{ display: "inline-block" }}>
                              Código de seguridad
                              </label>
                              <input
                              id="securityCode"
                              style={{ flexGrow: 1, width: "100%", height: "3rem" }}
                              type="text"
                              name="securityCode"
                              required
                              onChange={onChange}
                              autoComplete={"off"}
                              value={values.securityCode}/>
                          </div>
                          <div style={{ width: "33.3%", paddingTop: "1.2rem" }}>
                              <img
                              id="securityCodeImage"
                              alt="Security Code Challange"
                              style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                              paddingLeft: ".5rem",
                              paddingRight: ".5rem"
                              }}/>
                          </div>
                      </div>
                  </div>
                  </div>
                {error &&
                    <div className="row">
                      <div className="col-md-2">&nbsp;</div>
                        <div style={{ marginTop: "0.2rem" }} className="col-md-4 alert alert-danger text-center div-error">{error}</div>
                    </div>}
                {!error &&
                    <div className="row">
                      <div className="col-md-12">&nbsp;</div>
                    </div>}
                    <div className="row">
                    <div className="col-md-2">&nbsp;</div>
                        <div className="col-lg-4 text-center">
                            <button 
                            className="btn btn-danger btn-sm"
                            type="submit"
                            disabled={isSubmitting}>
                                Cambiar clave
                            </button>
                        </div>
                    </div>
                  
    <br/>
    <br/><br/>
    
    </div></form>
    
    </>
    );
}