import interbanking from "../img/ib_pago.png";
import bna from "../img/bna_pago.png";
import pagomiscuentas from "../img/pagomiscuentas.png";
import linkpagos from "../img/linkpagos.png";
import pagar from "../img/pagar-red_link.png";
import mercadopago from "../img/mp_pago.png";
import pagofacil from "../img/pf_pago.png";
import rapipago from "../img/rapipago.png";


const PayMethods =()=> {
    
    return(
        <div>
            <br/>
            <br/>
            <h3>Medios de pago disponibles</h3>
            <br/><br/>
            <div className="row">
                <div className="col-md-2">
                    <img src={pagar} alt="Pagar - Red Link" width={200}/>
                </div>
            
                <div className="col-md-2">
                    <img src={bna} alt="BNA" width={200}/>
                </div>
                <div className="col-md-2">
                    <img src={interbanking} alt="Interbanking" width={200}/>
                </div>
                <div className="col-md-2">
                    <img src={mercadopago} alt="Mercado Pago" width={200}/>
                </div>
                <div className="col-md-2">
                    <img src={pagofacil} alt="Pago Facil" width={200}/>
                </div>    
            </div>            
            <br/>
            <br/>
            <h6><strong>IMPORTANTE:</strong></h6>
            <h6><strong>NO SE ACEPTAN TRANSFERENCIAS BANCARIAS</strong></h6>
            <h6><strong>SU C&Oacute;DIGO DE LINK PAGOS ES: {0+window.CUIT.replace(/-/g, '')}</strong></h6>
            <br/><div>&nbsp;</div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default PayMethods;