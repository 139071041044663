import React, {useEffect, useState} from "react";
import MisAportes from "./MisAportes";
import PayMethods from "./PayMethods";
import MisDatos from "./MisDatos";
import MisPlanillas from "./MisPlanillas";
import GenerarBoleta from "./GenerarBoleta";
import ConsultarDeuda from "./ConsultarDeuda";
import CambiarClave from "./CambiarClave";
import Informacion from "./Informacion";
import Consultas from "./Consultas";

export default function ContainerLogged(props) {

    const [pagaintereses, setPagaIntereses] = useState(false);
    const pagarintereses=(estado)=>{
        setPagaIntereses(estado);

  }
    
    const [operacion, setOperacion] = useState(false);
    const operar=(estado)=>{
      setOperacion(estado);

  }


    const handleMisAportes = () => {

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        operar('MisAportes');
        setPagaIntereses(false);
        props.informar(false);
        props.consultar(false);

    };

    const handleMisDatos = () => {

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        operar('MisDatos');
        setPagaIntereses(false);
        props.informar(false);
        props.consultar(false);

    };

    const handleMisPlanillas = () => {

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        operar('MisPlanillas');
        setPagaIntereses(false);
        props.informar(false);
        props.consultar(false);

    };

    const handleGenerarBoleta = () => {

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        operar('GenerarBoleta');
        setPagaIntereses(false);
        props.informar(false);
        props.consultar(false);
        
    };

    const handleConsultarDeuda = () => {

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        operar('ConsultarDeuda');
        setPagaIntereses(false);
        props.informar(false);
        props.consultar(false);

    };

    const handleCambiarClave = () => {

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        operar('CambiarClave');
        setPagaIntereses(false);
        props.informar(false);
        props.consultar(false);

    };
    useEffect(() => {
        if(operacion  === 'CambiarClave') 
        {
            document.getElementById('CambiarClave').classList.add("active");
            document.getElementById('MisAportes').classList.remove("active");
            document.getElementById('MisDatos').classList.remove("active");
            document.getElementById('MisPlanillas').classList.remove("active");
            document.getElementById('GenerarBoleta').classList.remove("active");
            document.getElementById('ConsultarDeuda').classList.remove("active");
        };

        if(operacion  === 'ConsultarDeuda') 
        {
            document.getElementById('ConsultarDeuda').classList.add("active");
            document.getElementById('MisAportes').classList.remove("active");
            document.getElementById('MisDatos').classList.remove("active");
            document.getElementById('MisPlanillas').classList.remove("active");
            document.getElementById('GenerarBoleta').classList.remove("active");
            document.getElementById('CambiarClave').classList.remove("active");
        }

        if(operacion  === 'GenerarBoleta') 
        {
            document.getElementById('GenerarBoleta').classList.add("active");
            document.getElementById('ConsultarDeuda').classList.remove("active");
            document.getElementById('MisAportes').classList.remove("active");
            document.getElementById('MisDatos').classList.remove("active");
            document.getElementById('MisPlanillas').classList.remove("active");
            document.getElementById('CambiarClave').classList.remove("active");
        }

        if(operacion  === 'MisPlanillas') 
        {
            document.getElementById('MisPlanillas').classList.add("active");
            document.getElementById('GenerarBoleta').classList.remove("active");
            document.getElementById('ConsultarDeuda').classList.remove("active");
            document.getElementById('MisAportes').classList.remove("active");
            document.getElementById('MisDatos').classList.remove("active");
            document.getElementById('CambiarClave').classList.remove("active");
        }

        if(operacion  === 'MisDatos') 
        {
            document.getElementById('MisDatos').classList.add("active");
            document.getElementById('MisPlanillas').classList.remove("active");
            document.getElementById('GenerarBoleta').classList.remove("active");
            document.getElementById('ConsultarDeuda').classList.remove("active");
            document.getElementById('MisAportes').classList.remove("active");
            document.getElementById('CambiarClave').classList.remove("active");
        }

        if(operacion  === 'MisAportes') 
        {
            document.getElementById('MisAportes').classList.add("active");
            document.getElementById('MisDatos').classList.remove("active");
            document.getElementById('MisPlanillas').classList.remove("active");
            document.getElementById('GenerarBoleta').classList.remove("active");
            document.getElementById('ConsultarDeuda').classList.remove("active");
            document.getElementById('CambiarClave').classList.remove("active");
        }

    }, [operacion]);
    useEffect(() => {
    if(props.informa) 
        {
            document.getElementById('MisAportes').classList.remove("active");
            document.getElementById('MisDatos').classList.remove("active");
            document.getElementById('MisPlanillas').classList.remove("active");
            document.getElementById('GenerarBoleta').classList.remove("active");
            document.getElementById('ConsultarDeuda').classList.remove("active");
            document.getElementById('CambiarClave').classList.remove("active");
        }
    }, [props.informa]);

    useEffect(() => {
        if(props.consulta) 
            {
                document.getElementById('MisAportes').classList.remove("active");
                document.getElementById('MisDatos').classList.remove("active");
                document.getElementById('MisPlanillas').classList.remove("active");
                document.getElementById('GenerarBoleta').classList.remove("active");
                document.getElementById('ConsultarDeuda').classList.remove("active");
                document.getElementById('CambiarClave').classList.remove("active");
            }
        }, [props.consulta]);

    return(

        <div className="container mt-5">

                <br/><br/><br/>
                <div className="botonera">
                <span style={{'margin':'2px'}}><button
                id="MisAportes"
                onClick={handleMisAportes}
                type="button"
                className="btn btn-outline-primary btn-sm"
                >Mis aportes
                </button>
                </span>
                <span style={{'margin':'2px'}}><button
                id="MisDatos"
                onClick={handleMisDatos}
                type="button"
                className="btn btn-outline-primary btn-sm"
                >Mis datos</button>
                </span>
                <span style={{'margin':'2px'}}><button
                id="MisPlanillas"
                onClick={handleMisPlanillas}
                type="button"
                className="btn btn-outline-primary btn-sm"
                >Mis planillas</button>
                </span>
                <span style={{'margin':'2px'}}><button
                id="GenerarBoleta"
                onClick={handleGenerarBoleta}
                type="button"
                className="btn btn-outline-primary btn-sm"
                >Generar boleta</button>
                </span>
                <span style={{'margin':'2px'}}><button
                id="ConsultarDeuda"
                onClick={handleConsultarDeuda}
                type="button"
                className="btn btn-outline-primary btn-sm"
                aria-pressed="true"
                >Consultar deuda</button>
                </span>
                <span style={{'margin':'2px'}}><button
                id="CambiarClave"
                onClick={handleCambiarClave}
                type="button"
                className="btn btn-outline-primary btn-sm"
                aria-pressed="true"
                >Cambiar clave</button>
                </span>

            </div>
            <div className="row">
                <div className="col-md-2">&nbsp;</div>
                <div className="col-md-8">
            </div>
            
            {
            props.informa ?  
            <Informacion acceder={props.acceder} ingresar={props.ingresar}  />:
            props.consulta ?  
            <Consultas acceder={props.acceder} ingresar={props.ingresar}  />:
            operacion === 'MisAportes' ?
            <MisAportes operar={operar} />
            : operacion === 'MisDatos' ?
            <MisDatos operar={operar}/>
            : operacion === 'MisPlanillas' ?
            <MisPlanillas operar={operar}/>
            : operacion === 'GenerarBoleta' ?
            <GenerarBoleta operar={operar} acceder={props.acceder} pagaintereses={pagaintereses}/>
            : operacion === 'ConsultarDeuda' ?
            <ConsultarDeuda operar={operar} pagarintereses={pagarintereses} />
            : operacion === 'CambiarClave' ?
            <CambiarClave acceder={props.acceder} ingresar={props.ingresar}/> 
            
            :<PayMethods/>}

            </div>
            <div className="col-md-2">&nbsp;</div>

        </div>

    );
}