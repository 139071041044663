import cpb from "../img/cpb_logo.png";

const Footer =()=> {
    return(
        
            <footer className="py-2 bg-dark">
                <div className="container"><p className="m-0 text-center text-white"><a href="https://cpb-estudio.com.ar" target="_blank" rel="noreferrer"><img src={cpb} width="48" alt=""/></a> &copy;2022</p></div>
            </footer>
        
    )
}

export default Footer;