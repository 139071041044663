export default function NoMobile(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <strong>
                                <h1>Aplicación no habilitada para dispositivos móviles</h1>
                            </strong>
                        </div>
                        <div className="card-body text-center">
                            <br/><br/><br/>
                            <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="200" 
                            height="200" 
                            fill="currentColor" 
                            className="bi bi-file-lock2" 
                            viewBox="0 0 16 16">
                            <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z"/>
                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                            </svg>
                            <br/><br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
}