import { useState } from "react";
import Concepto from "./Concepto";
import PlanillaPorcentaje from "./PlanillaPorcentaje";
import PlanillaFijo from "./PlanillaFijo";
import PlanillaCoseguro from "./PlanillaCoseguro";
import BoletaGenerada from "./BoletaGenerada";

export default function GenerarBoleta(props) {
    
    const [isDisabled, setIsDisabled] = useState(false);
    const [planillamodelo, setPlanillaModelo] = useState(false);
    const [usamodelo, setUsaModelo] = useState(false);
    const [boletagenerada, setBoletaGenerada] = useState(false);
    
    const pagaintereses = props.pagaintereses;

    const imprimir=()=>{
        setBuscaplanilla(false);
        setBoletaGenerada(true);

  }
    
    const [buscaplanilla, setBuscaplanilla] = useState(false);
    const buscarplanilla = () => {
        setBuscaplanilla(true);
    }
    const quitarplanilla = () => {
        window.anio = null;
        window.mes = null;
        setBuscaplanilla(false);
        setIsDisabled(false);
        setPlanillaModelo(false);
        setUsaModelo(false);
    }

    const handleReacer = () => {

        window.anio = null;
        window.mes = null;
        setBuscaplanilla(false);
        setIsDisabled(false);
        setPlanillaModelo(false);
        setUsaModelo(false);
        
    }

    const handlechange = (elemento) => {
        setPlanillaModelo(elemento);
        if(elemento.length === 31)
        {
            setIsDisabled(true);
            setUsaModelo(true);
        }
    }
    return(
        <div>
            <h3>Generar Boleta&nbsp;
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="32" 
            height="32" 
            fill="currentColor" 
            className="bi bi-upc-scan" 
            viewBox="0 0 16 16">
  <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"/>
</svg>&nbsp;</h3>
            <div className="row">
                <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
            </div>
                <div className="row">
                    <div className="col-md-3">
                { buscaplanilla && 
                <span
                style={{'cursor':'pointer','fontSize':'24px', 'color':'#ababab'}}
                onClick={handleReacer}>⟲
                    <span
                    style={{'cursor':'pointer','fontSize':'14px', 'color':'#ababab'}}
                    onClick={handleReacer}> Rehacer</span>
                </span>
                }
                </div>

                { buscaplanilla && window.codconcepto !== 5 && <>
                    <div className="col-md-2" style={{'fontSize':'14px', 'color':'#9b9b9b'}}>Puede reutilizar una planilla generada con anterioridad</div>
                    <div className="col-md-3">
                        <input 
                        className="form-control input-sm" 
                        type="text"
                        disabled={isDisabled}
                        onChange={(ev) => handlechange(ev.target.value)}
                        placeholder="Ingresar número de planilla modelo"
                        length="31"/>
                    </div></>}
            </div>    
            <div>
            <br/>
            
            
            
            {!buscaplanilla && pagaintereses ? <BoletaGenerada imprimir={imprimir} pagaintereses={props.pagaintereses} operar={props.operar} /> :
            !buscaplanilla && !boletagenerada ? <Concepto buscarplanilla={buscarplanilla}  /> : 
            window.marPorcentaje && !boletagenerada ? <PlanillaPorcentaje quitarplanilla={quitarplanilla} imprimir={imprimir} planillamodelo={planillamodelo} usamodelo={usamodelo} /> :
            window.codconcepto === 5 && !boletagenerada ? <PlanillaCoseguro quitarplanilla={quitarplanilla} imprimir={imprimir} /> :
            !window.marPorcentaje && !boletagenerada ? <PlanillaFijo quitarplanilla={quitarplanilla} imprimir={imprimir} planillamodelo={planillamodelo} usamodelo={usamodelo} /> :
            !buscaplanilla && boletagenerada && !pagaintereses ? <BoletaGenerada imprimir={imprimir} pagaintereses={props.pagaintereses} operar={props.operar} /> :
            
            ''
                }
            </div>
            <br/>
            <br/><br/><br/><br/><br/>
        </div>
    );
}