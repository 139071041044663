import React, { useEffect, useState, useRef } from "react";
export default function IngresarSinClave(props) {

  
  const[error, setError]= useState(null);
  const[espera, setEspera]= useState(false);
  const refCUIT = useRef(null);
  const refnroPlanilla = useRef(null);
  const refemail = useRef(null);
  const refimpDepositado = useRef(null);

  const handleLogin = () => {
    
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
  });
  
    props.acceder(false);
    props.ingresar(false);
    props.registrar(false);
}

  const SECURITY_CODE_LENGTH = 4;
  const [values, setValues] = useState({ securityCode: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [securityCode, setSecurityCode] = useState("");

  const generateSecurityCodeImage = () => {
    const code = Array.from(Array(SECURITY_CODE_LENGTH), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join("");

    const securityCodeImageElement = document.getElementById(
      "securityCodeImage"
    );
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 50;
    canvas.height = 20;

    const imgElement = document.createElement("img");

    imgElement.addEventListener("load", loadImage);
    imgElement.src = `data:image/svg+xml,${encodeURIComponent(
      `<svg xmlns="http://www.w3.org/2000/svg" width="150" height="100"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml" style="display:block;"><span style="margin:auto;">${code}</span></div></foreignObject></svg>`
    )}`;

    function loadImage(e) {
      ctx.drawImage(e.target, 0, 0);
      securityCodeImageElement.src = canvas.toDataURL();
    }

    setSecurityCode(code);
  };

  const onChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    event.persist();
    setValues({ ...values, [name]: value });
  };

  const onSubmit = (event) => {
    setIsSubmitting(true);

    if (event) event.preventDefault();

    setTimeout(() => {
      if (values.securityCode !== securityCode) {
        setError("Código de seguridad incorrecto");
        generateSecurityCodeImage();
        setIsSubmitting(false);

        return;
      }

      setEspera(true);

    const data = {
        "usuario"       : refCUIT.current.value,
        "email"         : refemail.current.value,
        "nroPlanilla"   : refnroPlanilla.current.value,
        "impDepositado" : refimpDepositado.current.value
        
    };

    fetch(`${window.$SERVER_API}loginsinclave.php`
    ,
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        })
        .then((response) => response.json())
        .then((respuestaJson) => {
            
            if(respuestaJson.conectado)
            {
              props.acceder(respuestaJson.conectado);
              respuestaJson.conectado ? window.token = respuestaJson.token : props.acceder(false);
              respuestaJson.conectado ? window.CUIT = respuestaJson.usuario : props.acceder(false);
              respuestaJson.conectado ? window.desEmpresa = respuestaJson.desEmpresa : props.acceder(false);
              setError(respuestaJson.error);
              setEspera(false);
            }
            else {
              setError(respuestaJson.error);
              setEspera(false);
            }
        
        });  
    
    
    
      //alert("Easy peasy lemon squeeze");
      
      generateSecurityCodeImage();
      setIsSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    generateSecurityCodeImage();
  }, []);

  return (
    <>
    <br/><br/><br/><br/><br/><br/>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h3><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-file-lock2" viewBox="0 0 16 16">
              <path d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1zm2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224z"/>
              <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
              </svg>
              &nbsp;Ingresar sin clave
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
      </div>
      <div className="row">
        <div className="col-md-12"><br/>Una posibilidad de acceso sin clave es a través de una boleta generada con anterioridad; deberá ingresar ciertos datos referenciados a la misma. Esto incluye los 31 dígitos que conforman el número de planilla, la CUIT de la empresa y el total depositado expresado con dos decimales tal cual figura en la boleta de pago. Además deberá cargar el correo electrónico con el que fue registrada la empresa.</div>
      </div>
    </div>
      <br/>
    <div className="container" style={{ backgroundColor: "#fbfbfb" }}>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-12">
            <br/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <label 
              htmlFor="cuit">
                  CUIT/CUIL
              </label>
              <input 
              type="text"
              maxLength={13} 
              className="form-control" 
              id="cuit" 
              ref={refCUIT}
              aria-describedby="CUIT/CUIL" 
              placeholder="CUIT/CUIL"/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="cnroPlanilla">Nº de planilla</label>
              <input
                  className="form-control"
                  id="nroPlanilla"
                  ref={refnroPlanilla}
                  type="text"
                  maxLength={31}
                  placeholder="Ingresar número de planilla"
                  autoComplete={"off"} />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label htmlFor="impDepositado">Total depositado</label>
              <input
              className="form-control"
              id="impDepositado"
              ref={refimpDepositado}
              type="number"
              step="0.01"
              placeholder="Total depositado"
              autoComplete={"off"}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <br/>
            <div className="form-group">
              <label htmlFor="email">e-mail</label>
              <input
                  className="form-control"
                  id="email"
                  ref={refemail}
                  type="email"
                  maxLength={45}
                  placeholder="Correo electrónico"
                  autoComplete={"off"} />
            </div>
          </div>
          <div className="col-md-4">
            <br/>
            <div style={{ display: "flex" }}>
              <div style={{ width: "66.6%"}}>
                <label htmlFor="securityCode" style={{ display: "inline-block" }}>
                  Código de seguridad
                </label>
                <input
                  id="securityCode"
                  style={{ flexGrow: 1, width: "100%", height: "3rem" }}
                  type="text"
                  name="securityCode"
                  required
                  onChange={onChange}
                  autoComplete={"off"}
                  value={values.securityCode}/>
              </div>
              <div style={{ width: "33.3%", paddingTop: "1.2rem" }}>
                <img
                  id="securityCodeImage"
                  alt="Security Code Challange"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                    paddingLeft: ".5rem",
                    paddingRight: ".5rem"
                  }}/>
              </div>
            </div>
          </div>
          <div className="col-md-4">&nbsp;</div>
        </div>
        {error &&
        <div className="row">
            <div style={{ marginTop: "0.2rem" }} className="col-md-8 alert alert-danger text-center">{error}</div>
        </div>}
        <br/>
        <div className="row">
          
          <div className="col-md-8 text-center">
            <button
            type="reset"
            className="btn btn-light"
            onClick={handleLogin}>
            Cancelar
            </button>
            <button
            className="btn btn-danger"
            type="submit"
            disabled={isSubmitting}>Ingresar
            </button>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-12">
            <br/>
          </div>
        </div>
      </form>
    </div>
    <br/><br/><br/>
    </>
  );
}