import {useState, useEffect} from "react";
import Periodo from "./Periodo";


export default function Concepto(props) {

    const urlConcepto = `${window.$SERVER_API}concepto.php`;


    const enviarData = async(url, data)=>{

        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        });
        const json = await resp.json();

        return json;
    }

    const buscarplanilla = props.buscarplanilla;
    const[respuesta, setRespuesta]= useState();
    const[error, setError]= useState(null);

    const handleConcepto = async()=>{


        const data = {
            "usuario" : window.CUIT,
            "token"   : window.token
        };

        const respuestaJson = await enviarData(urlConcepto, data);
        
        setRespuesta(respuestaJson);
        setError(respuestaJson.error);
    }

        const[selected, setSelected] = useState();



        const handleChange = event => {
            
            if(event.target.value !== "Seleccionar")
            {
            setSelected(JSON.parse(event.target.value).marPeriodo);
            window.codconcepto = JSON.parse(event.target.value).codConcepto;
            window.desconcepto = JSON.parse(event.target.value).desConcepto;
            JSON.parse(event.target.value).marPorcentaje === 'on' ? window.marPorcentaje = true :
            window.marPorcentaje = false ;

            }


          };
          // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if(!selected)
    {
        window.anio = null;
        window.mes = null;
    }
  },[selected]);

    return (

    <div className="row">
        <div className="col-md-1">&nbsp;</div>
        <div className="col-md-8 bg-light">
            <div onClick={handleConcepto}><br/><h5>Concepto</h5>
                <select
                onClick={handleChange}

                className="form-control">
                {!respuesta ? <option value={null}>Seleccionar</option> : ''}
                {error ? <div className="alert alert-danger"> {error}</div>  :

                respuesta &&
                respuesta.map((item, key) => (
                                <option key={item.id} value={JSON.stringify({key, value:item.value, codConcepto:item.codConcepto, desConcepto:item.desConcepto, marPeriodo:item.marPeriodo, marPorcentaje:item.marPorcentaje})}>{item.desConcepto}</option>



        ))}
                </select>
                {selected === 1 ? <Periodo buscarplanilla={buscarplanilla} /> : selected === 0 ?

                <div><br/>
                <button
                onClick={buscarplanilla}
                className="btn btn-primary">Comenzar
                </button>
                </div>

                : ''}
                <br/>
            <br/><br/><br/>
            
            </div>
        </div>
        <div className="col-md-3">&nbsp;</div><br/>
            <br/>
            <br/>
            <br/>
            <br/>
    </div>
    );
}