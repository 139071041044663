import {useState} from "react";
import LoadingOverlay from 'react-loading-overlay-ts';

export default function MisPlanillas(props) {

    const urlMisPlanillas = `${window.$SERVER_API}misplanillas.php`;

    const enviarData = async(url, data)=>{

        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        });
        const json = await resp.json();

        return json;

    }
        const[respuesta, setRespuesta]= useState();
        const[error, setError]= useState(null);
        const[espera, setEspera]= useState(false);

        const handleMisPlanillas = async()=>{
            
            setRespuesta([]);
            setEspera(true);
            const data = {
            "usuario" : window.CUIT,
            "token"   : window.token
        };

        const respuestaJson =  await enviarData(urlMisPlanillas, data);
        setRespuesta(respuestaJson);
        setError(respuestaJson.error);
        setEspera(false);

    }
    return(

        <div>
            <h3>Mis Planillas&nbsp;
            {!espera &&
            <svg
            onClick={handleMisPlanillas}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-eye-fill"
            style={{'cursor':'pointer'}}
            viewBox="0 0 16 16">
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
            </svg>}
            &nbsp;
            {!espera &&
            <span
            onClick={handleMisPlanillas}
            style={{'fontSize':'16px', 'cursor':'pointer'}}>Ver</span>}
            </h3>
            <div>
            <div className="row">
                <div className="col-md-12 bg-aatrac-flat">&nbsp;</div>
            </div>
            <div className="row">
                <div className="col-md-4 bg-aatrac" style={{'color':'white'}} >Planilla Nº</div>
                <div className="col-md-2 bg-aatrac" style={{'color':'white'}} >Concepto</div>
                <div className="col-md-2 bg-aatrac" style={{'color':'white'}} >Per&iacute;odo</div>
                <div className="col-md-2 bg-aatrac" style={{'color':'white'}}>Total a depositar</div>
                <div className="col-md-2 bg-aatrac" style={{'color':'white'}} >Fecha de ingreso</div>
            </div>
            {espera &&
            <LoadingOverlay
            active={true}
            spinner
            text='Cargando respuesta del servidor...'            >
            <p><br/><br/></p><br/><br/><br/>
            </LoadingOverlay>}
                {error ? <div className="alert alert-danger div-error"> {error}</div> :
            respuesta &&
                respuesta.map((item) => (
                <div className="row" key={item.id}>
                    <div className="col-md-4" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }><a href={`${window.$SERVER_API}fpdf185/planilla.php?nroplanilla=${item.nroPlanilla}`} target="_blank" title="Ver" rel="noreferrer">{item.nroPlanilla}</a></div>
                    <div className="col-md-2" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.desConcepto}</div>
                    <div className="col-md-2" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.periodo}</div>
                    <div className="col-md-2 text-end" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(item.impDepositado)}</div>

                    <div className="col-md-2" style={item.id%2===0 ? {'backgroundColor':'#dadada'} : {'backgroundColor':'#fafafa'} }>{item.fecIngresoFilter}</div>

                </div>
                ))}
    </div>
    <br/><br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/><br/><br/><br/>
        </div>

    );
}