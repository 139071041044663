import { useRef, useEffect, useState } from "react";
import CuilInput from './CuilInput';
import { ValidaCuil } from "./ValidaCuil";
import swal from 'sweetalert';

export default function PlanillaFijo(props) {

    const[espera, setEspera]= useState(false);

    window.periodo = parseInt(window.anio.toString() + window.mes.toString() );

    const EXP_REG_LETRAS_ESPACIO = new RegExp(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, 'i');

    const [FIJO, setFIJO] = useState(null);
    const [cargandofijoconcepto, setCargandoFijoConcepto] = useState(true);

    const [INTERES, setINTERES] = useState(0);
    const [cargandoporcentajeinteres, setCargandoPorcentajeInteres] = useState(true);

    //json planilla
    const [respuesta, setRespuesta] = useState([]);

    // empleado cargado
    const [item, setItem] = useState(false);
    const [cuil, setCuil] = useState('');

    let apellido = useRef(null);
    let nombre = useRef(null);

    const [aporte, setAporte] = useState(0);
    const [interes, setInteres] = useState(0);
    const [total, setTotal] = useState(0);

    const [error, setError] = useState(null);
    const [erroritem, setErrorItem] = useState(null)
    const [totalpagar, setTotalPagar] = useState(0);

    const handleCuil = ({ target: { value } }) => {

        setError(false);
        setCuil(value);
    }

    const DeleteItem = (id) => {

        const filtro = respuesta.filter(item => item.id !== id);
        setRespuesta(filtro);

    }


    const handleAgregarEmpleado = () => {

        if (!ValidaCuil(cuil.replace(/-/g, ""))) {
            setError('CUIL incorrecta');
            return;
        }
        let existeCuil = respuesta.find(element => element.cuil === cuil);
        if (existeCuil) {
            setError('La CUIL ya fue cargada');
            return;
        }
        if (!EXP_REG_LETRAS_ESPACIO.test(apellido.current.value)) {
            setError('Ingresar apellido válido');
            return;
        }
        if (apellido.current.value.length === 0) {
            setError('Ingresar apellido');
            return;
        }
        if (!EXP_REG_LETRAS_ESPACIO.test(nombre.current.value)) {
            setError('Ingresar nombre válido');
            return;
        }
        if (nombre.current.value.length === 0) {
            setError('Ingresar nombre');
            return;
        }
        setError(false);


        let ultId = 0;

        if (respuesta.length > 0)
            ultId = Math.max(...respuesta.map(e => e.id));

        const nuevo = respuesta.concat({
            id: (ultId + 1),
            cuil: cuil,
            apellido: apellido.current.value.toUpperCase(),
            nombre: nombre.current.value.toUpperCase(),
            remuneracion: 0.00,
            codzona: '',
            codgrupo: '',
            zona: '',
            grupo: '',
            aporte: Math.round(parseFloat(FIJO)*100)/100,
            interes: Math.round(parseFloat(FIJO* INTERES)*100)/100 ,
            total: Math.round(parseFloat(FIJO)*100)/100 + Math.round(parseFloat(FIJO* INTERES)*100)/100 
        });

        setRespuesta(nuevo);

        setCuil('');
        apellido.current.value = '';
        nombre.current.value = '';
        setAporte(0);
        setInteres(0);
        setTotal(0);

    }

    const handleGenerarBoleta = () => {
        
        swal({
            title: "¿Está seguro?",
            text: "Una vez generada la nómina de empleados para el concepto y período seleccionados, no podrá modificarse ni eliminarse.",
            icon: "warning",
            buttons: ["Cancelar", "Generar Boleta"],
            dangerMode: true,
          })
          .then((willGenerate) => {
            if (willGenerate) {
                setEspera(true);
                
                const data = {
                    "usuario"   : window.CUIT,
                    "token"     : window.token,
                    "concepto"  : window.codconcepto,
                    "periodo"   : window.periodo,
                    "json_data"      : (respuesta)
                };
        
        
                fetch(`${window.$SERVER_API}generaboleta.php`
                ,
                    {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type':'application/json'
                        }
        
                    })
                    .then((response) => response.json())
                    .then((porcentaje) => {
                        
                        window.nroPlanilla = porcentaje.nroPlanilla;
                        window.totalpagarboletagenerada = totalpagar;
                        setEspera(false);
                        props.imprimir(true);
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    
                    });
            } 
          });
    }



    useEffect(() => {
        const obtenerTotales = array => array.reduce((acumulador, { total }) => parseFloat(acumulador) + parseFloat(total), 0);
        setTotalPagar(obtenerTotales(respuesta));

    }, [respuesta, item]);
    
    useEffect(() => {

        const data = {
            "usuario"   : window.CUIT,
            "token"     : window.token,
            "concepto"  : window.codconcepto,
            "periodo"   : window.periodo
        };

        fetch(`${window.$SERVER_API}fijoconcepto.php`
        ,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type':'application/json'
                }

            })
            .then((response) => response.json())
            .then((fijo) => {
                if(!fijo.error)
                {
                    setFIJO(fijo.valor); 
                    setCargandoFijoConcepto(false);
                }
                else {
                    swal("Error", fijo.error, "warning", {
                    button: "Aceptar",
                    });               
                }
            });
    }, []);

    useEffect(() => {

        const data = {
            "usuario"   : window.CUIT,
            "token"     : window.token,
            "concepto"  : window.codconcepto,
            "periodo"   : window.periodo
        };

        fetch(`${window.$SERVER_API}porcentajeinteres.php`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type':'application/json'
                }
            })
            .then((response) => response.json())
            .then((porcentaje) => {
                if(!porcentaje.error)
                {
                    setINTERES(porcentaje.porcentajeinteres); 
                    setCargandoPorcentajeInteres(false);
                }
                else {
                    swal("Error", porcentaje.error, "warning", {
                    button: "Aceptar",
                    });
                }
            });
    }, []);

useEffect(() => {
    if(!props.usamodelo){
        setRespuesta([]);
        const data = {
            "usuario"           : window.CUIT,
            "token"             : window.token,
            "concepto"          : window.codconcepto,
            "periodo"           : window.periodo
        };

    fetch(`${window.$SERVER_API}planillafijo.php`,
            {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        })
        .then((response) => response.json())
        .then((planilla) => {
            
            if(!planilla.error)
            {
                setRespuesta(planilla);
            }
            else {
                swal("Información", planilla.error, "warning", {
                button: "Aceptar",
                });
            }
        });

}
}, [props.usamodelo]);

useEffect(() => {

if(props.usamodelo)
{
    setRespuesta([]);
    
            const data = {
                "usuario"           : window.CUIT,
                "token"             : window.token,
                "concepto"          : window.codconcepto,
                "periodo"           : window.periodo,
                "nroPlanilla"       : props.planillamodelo,
            };
    
            fetch(`${window.$SERVER_API}planillafijomodelo.php`,
                    {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type':'application/json'
                    }
    
                })
                .then((response) => response.json())
                .then((planilla) => {
                    
                    if(!planilla.error)
                    {
                        setRespuesta(planilla);
                    }
                    else {
                        swal("Información", planilla.error, "warning", {
                        button: "Aceptar",
                        });
                    }
                });
     }
}, [props.usamodelo]);

    useEffect(() => {

        if (ValidaCuil(cuil.replace(/-/g, ""))) {

            const data = {
                "usuario" : window.CUIT,
                "token"   : window.token,
                "cuil"    : cuil.replace(/-/g, "")
            };

            fetch(`${window.$SERVER_API}buscaempleado.php`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type':'application/json'
                }

            })
                .then((response) => response.json())
                .then((empleado) => {
                    if(!empleado.error)
                    {
                        apellido.current.value = empleado[0].apellido;
                        nombre.current.value = empleado[0].nombre;
                    }
                    else 
                    {
                        swal("Información", empleado.error, "warning", {
                        button: "Aceptar",
                    });
                    }
                });
        }
    }, [cuil]);

    return (<> {!cargandofijoconcepto && !cargandoporcentajeinteres &&
        <div>
            <br />
            <div className="row">
                <div className="col-sm-6"><h4>Empresa</h4></div>
                <div className="col-sm-6"><h4>Concepto</h4></div>
            </div>
            <div className="row">
                <div className="col-sm-6">CUIT:&nbsp;<strong>{window.CUIT}</strong>{/*token:{window.token}*/} </div>
                <div className="col-sm-6"><strong>{/*Cod:{window.codconcepto}*/}{window.desconcepto}&nbsp;Valor: {FIJO}</strong></div>
            </div>
            <div className="row">
                <div className="col-sm-6">Denominaci&oacute;n:&nbsp;{window.desEmpresa}</div>
                <div className="col-sm-6">📅&nbsp;Per&iacute;odo:&nbsp;<strong>{window.anio}{window.mes}{/*valor:{window.periodo}*/}</strong> Interés: {INTERES.toLocaleString('es-AR')}</div>
            </div>

            <br />
            <div className="row">
                <div className="col-sm-12"><h5>👤&nbsp;Empleados declarados</h5></div>
            </div>
            <div className="row">
                <div className="col-sm-2 bg-aatrac" style={{ 'color': 'white' }}>CUIL</div>
                <div className="col-sm-2 bg-aatrac" style={{ 'color': 'white' }}>Apellido</div>
                <div className="col-sm-2 bg-aatrac" style={{ 'color': 'white' }}>Nombre</div>
                <div className="col-sm-2 bg-aatrac" style={{ 'color': 'white' }}>&nbsp;</div>
                <div className="col-sm-1 bg-aatrac" style={{ 'color': 'white' }}>Aporte</div>
                <div className="col-sm-1 bg-aatrac" style={{ 'color': 'white' }}>Inter&eacute;s</div>
                <div className="col-sm-1 bg-aatrac" style={{ 'color': 'white' }}>Total ARS</div>
                <div className="col-sm-1 bg-aatrac" style={{ 'color': 'white' }}>&nbsp;</div>
            </div>
            {
                respuesta.map(e =>

                    <div className='row justify-content-start bg-row-gris' id={'rowempleado' + e.id} key={e.id}>
                        <div className='col-md-2 bg-row-gris'>{e.cuil}</div>

                        <div className='col-md-2 text-start bg-row-gris'>
                            <input
                                id={'a' + e.id}
                                type='text'
                                defaultValue={e.apellido} disabled />
                        </div>

                        <div className='col-md-2 text-start bg-row-gris'>
                            <input
                                id={'n' + e.id}
                                type='text'
                                defaultValue={e.nombre} disabled />
                        </div>

                        <div className='col-md-2 text-start bg-row-gris'>
                            &nbsp;
                        </div>
                        <div className='col-md-1 text-end bg-row-gris' id={'aporte' + e.id}>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(e.aporte)}</div>
                        <div className='col-md-1 text-end bg-row-gris' id={'interes' + e.id}>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(e.interes)}</div>
                        <div className='col-md-1 text-end bg-row-gris' id={'total' + e.id}>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(e.total)}</div>
                        <div className='col-md-1'>
                        <svg
                        onClick={() => DeleteItem(e.id)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        style={{ 'cursor': 'pointer' }}
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        <title>Eliminar</title>
                        </svg>
                        </div>
                    </div>

                )
            }{erroritem &&
                <div className="row">
                    <div className="alert alert-danger col-md-12 text-center div-error"> {erroritem}</div>
                </div>}
            <br />
                <div className="row">
                    <div className="col-md-11 text-end bg-aatrac2" style={{ 'color': 'black' }} id='totalpagar'>Total ARS: {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(totalpagar)}</div>
                    <div className="col-md-1">&nbsp;</div>
                </div>
            <br />
            {!erroritem && totalpagar > 0 &&
                <div className="row">
                    <div className="col-md-12 text-center">
                        <button
                            onClick={props.quitarplanilla}
                            className="btn btn-light">
                            Cancelar
                        </button>
                        <button
                            className="btn btn-danger"
                            onClick={handleGenerarBoleta}>
                            Generar Boleta
                        </button>
                    </div>
                </div>}
            <br />

            <div className="row">
                <div className="col-sm-12"><h6>Agregar empleado</h6></div>
            </div>
            <div className="row">
                <div className="col-sm-2 bg-light">CUIL</div>
                <div className="col-sm-2 bg-light">Apellido</div>
                <div className="col-sm-2 bg-light">Nombre</div>
                <div className="col-sm-2 bg-light">&nbsp;</div>
                <div className="col-sm-1 bg-light">Aporte</div>
                <div className="col-sm-1 bg-light">Inter&eacute;s</div>
                <div className="col-sm-1 bg-light">Total ARS</div>
                <div className="col-sm-1 bg-light">&nbsp;</div>
            </div>
            <div className="row">
                <div className='col-sm-2 bg-light'>
                    <CuilInput
                        value={cuil}
                        onChange={handleCuil}
                        autoComplete={"off"}
                    />

                </div>
                <div className="col-sm-2 bg-light">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Apellido"
                        ref={apellido}
                        onClick={() => setError(false)}
                    />
                </div>
                <div className="col-sm-2 bg-light">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Nombre"
                        ref={nombre}
                        onClick={() => setError(false)}
                    />
                </div>
                <div className="col-sm-2 bg-light">
                    &nbsp;
                </div>
                <div className="col-sm-1 bg-light text-end">
                    <span>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(FIJO)}</span>
                </div>
                <div className="col-sm-1 bg-light text-end" >
                    <span>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(FIJO * INTERES)}</span>
                </div>
                <div className="col-sm-1 bg-light text-end">
                    <span>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(FIJO*1 + (FIJO * INTERES))}</span>
                </div>
                <div className="col-sm-1 bg-light">
                    <button
                        onClick={handleAgregarEmpleado}
                        className="btn btn-danger btn-sm"
                        >Agregar
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 bg-light"><h6>&nbsp;</h6></div>
            </div>
            
            {error &&
                <div className="row">
                    <div className="alert alert-danger col-md-12 text-center div-error"> {error}</div>
                </div>}
            
            <br />
            <br /><br />
        </div>

            }</>);
}