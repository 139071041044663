import {useState} from "react";
import swal from 'sweetalert';

export default function Periodo(props) {

    const urlPeriodo = `${window.$SERVER_API}anio.php`;

    const enviarData = async(url, data)=>{

        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json'
            }

        });
        const json = await resp.json();

        return json;
    }

    const buscarplanilla = props.buscarplanilla;

    const[respuesta, setRespuesta]= useState();
    const[error, setError]= useState(null);

    const handlePeriodo = async()=>{
        
            const data = {
                "usuario" : window.CUIT,
                "token"   : window.token


            };

        const respuestaJson = await enviarData(urlPeriodo, data);

        setRespuesta(respuestaJson);
        setError(respuestaJson.error);

        
        

}

        const[selected, setSelected] = useState();
        const handleChange = event => {

        setSelected(JSON.parse(event.target.value).anio);
        window.anio = JSON.parse(event.target.value).anio;
        
        const data = {
            "usuario"   : window.CUIT,
            "token"     : window.token,
            "concepto"  : window.codconcepto,
            "periodo"   : window.anio+''+window.mes
        };


        fetch(`${window.$SERVER_API}existeplanilla.php`
        ,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type':'application/json'
                }

            })
            .then((response) => response.json())
            .then((respuesta) => {
                
                //alert(JSON.stringify(respuesta))
                if(respuesta.existe)
                {
                    swal("Aviso", "Ya existe una planilla generada para ese período.", "warning", {
                        button: "Aceptar",
                      });
                }

                
            });

        };

        const handlemes = (e)=>{
            window.mes = e.target.value
            
                            
                const data = {
                    "usuario"   : window.CUIT,
                    "token"     : window.token,
                    "concepto"  : window.codconcepto,
                    "periodo"   : window.anio+''+window.mes
                };
        
        
                fetch(`${window.$SERVER_API}existeplanilla.php`
                ,
                    {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type':'application/json'
                        }
        
                    })
                    .then((response) => response.json())
                    .then((respuesta) => {
                        
                        //alert(JSON.stringify(respuesta))
                        if(respuesta.existe)
                        {
                            swal("Aviso", "Ya existe una planilla generada para ese período.", "warning", {
                                button: "Aceptar",
                              });
                        }
        
                        
                    });
            
            

              
        }


    return (
        <div><br/>
        <div className="row">
            <div className="col-md-12"><h5>📅&nbsp;Per&iacute;odo</h5></div>
        </div>
        <div className="row">

            <div className="col-md-2">Año
                <select
                onClick={handlePeriodo}
                onChange={handleChange}
                className="form-control">
                    {window.anio == null && <option value={null}>Seleccionar</option>}
                {error ? <div className="alert alert-danger"> {error}</div>  :

                respuesta &&
                respuesta.map((item, key) => (
                                <option key={item.id} value={JSON.stringify({key, value:item.value, anio:item.anio})}>{item.anio}</option>



        ))}
                </select>
            </div>
            <div className="col-md-2">Mes
                <select className="form-control"
                onChange={handlemes}>
                    {window.mes == null && <option value={null}>Seleccionar</option>}
                    <option value={"01"}>Enero</option>
                    <option value={"02"}>Febrero</option>
                    <option value={"03"}>Marzo</option>
                    <option value={"04"}>Abril</option>
                    <option value={"05"}>Mayo</option>
                    <option value={"06"}>Junio</option>
                    <option value={"07"}>Julio</option>
                    <option value={"08"}>Agosto</option>
                    <option value={"09"}>Septiembre</option>
                    <option value={"10"}>Octubre</option>
                    <option value={"11"}>Noviembre</option>
                    <option value={"12"}>Diciembre</option>
                </select>
            </div>
            <div className="col-md-2">{respuesta && window.anio !== null
            && window.mes !== null ?

            <div><br/>
                <button
                onClick={buscarplanilla}
                className="btn btn-primary">Comenzar
                </button>
                
                </div>

            : ''}</div>
        </div>
        </div>
    );
}